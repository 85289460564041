import React, { useState, createContext } from 'react';
import PropTypes from 'prop-types';
import blueTheme from '../styles/themes';

export const ThemeContext = createContext(null);

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(blueTheme);

  ThemeProvider.propTypes = {
    children: PropTypes.string.isRequired,
  };

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
