/* Content for the publication page and its component */

// Importing various PDF's from the resource
import NULT from '../resources/National Uniform Legislation table - 2020 final.pdf';
import UDP from '../resources/Uniform-drafting-protocol-4th-edition(1).pdf';
import History from '../resources/appendix2_history.pdf';

// Data for the accordion component
export const publicationAccordionData = [
  {
    id: 1,
    heading: 'PCC protocol on drafting national uniform legislation',
    pdfLink: UDP,
  },
  {
    id: 2,
    heading:
      'Australian national uniform law schemes and associated legislation of participating jurisdictions',
    pdfLink: NULT,
  },
  {
    id: 3,
    heading:
      ' History of PCC (article by Dennis Murphy, former New South Wales Parliamentary Counsel, prepared for the PC News and Views newsletter)',
    pdfLink: History,
  },
];

// Page Title for the publication page
export const pageTitle = 'Publications';
