/* Privacy page of the application showing privacy policy of organisation */
/* Dependencies
 * Components - CustomFooter.jsx
 * Styling - Hyperlink.js, Wrapper.js, Heading.js, Grid.js
 * Data - Sitemap.js
 */

import React from 'react';
import CustomFooter from '../components/CustomFooter';
import { PageTitle } from '../styles/Heading';
import { Wrapper } from '../styles/Wrapper';
import { Hyperlink } from '../styles/Hyperlink';
import { siteMapData, pageTitle } from '../data/Sitemap';

// Main function of the privacy page
export default function Privacy() {
  return (
    <>
      <Wrapper>
        <PageTitle>{pageTitle}</PageTitle>
        <ul style={{ marginLeft: '2rem' }}>
          {siteMapData.map((data) => (
            <li style={{ marginBottom: '1rem' }}>
              <Hyperlink href={data.link}>{data.text}</Hyperlink>
            </li>
          ))}
        </ul>
      </Wrapper>
      <CustomFooter />
    </>
  );
}
