/* Home page of the application with welcome text and PCC images */
/* Dependencies
 * Components -  CustomFooter.jsx
 * Styling - Text.js, Wrapper.js, Heading.js, Image,js
 * Data - Home.js
 */

import React from 'react';
import CustomFooter from '../components/CustomFooter';
import { HomeText } from '../styles/Text';
import { PageTitle } from '../styles/Heading';
import {
  Wrapper,
  HomeSubWrapper,
  HomePageImageWrapper,
} from '../styles/Wrapper';
import { imageData, pageTitle, introText } from '../data/Home';
import { HomePageImage } from '../styles/Image';

// Main function calling various components
export default function Home() {
  return (
    <>
      <Wrapper>
        <PageTitle>{pageTitle}</PageTitle>
        <HomeSubWrapper>
          <HomeText>{introText}</HomeText>
          <HomePageImageWrapper>
            {imageData.map((item) => (
              <HomePageImage key={item.alt} src={item.url} alt={item.alt} />
            ))}
          </HomePageImageWrapper>
        </HomeSubWrapper>
      </Wrapper>
      <CustomFooter />
    </>
  );
}
