/* Icon based styling used across projects */
import styled from 'styled-components';

// Footer Icon box to hold icon and text
export const FooterIconBox = styled.div`
  width: 40%;
  text-align: center;
`;

// style for the portal icon in the header
export const headerIconStyle = {
  color: '#fff',
  size: '2.8rem',
};

// style for the hyperlink icon in tab page
export const linkTabIconStyle = {
  backgroundColor: '#bdbdbd',
  borderRadius: '200px',
  padding: '0.4rem',
};

// style for the other links icon in related link page
export const relatedIconStyle = {
  backgroundColor: '#bdbdbd',
  borderRadius: '200px',
  padding: '0.4rem',
};

// style for the contact Page icons
export const contactPageIconStyle = {
  color: '#0b186d',
  fontSize: '1.25rem',
  marginRight: '1rem',
};
