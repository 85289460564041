/* Content of the Member details page */

// Importing images of various members from the resource folders
import Annette from '../resources/Annette.png';
// import Farnan from '../resources/Farnan.jpg';
import Keyes from '../resources/Keyes.jpg';
import Lawn from '../resources/Lawn.jpg';
import Travers from '../resources/Travers.jpg';
import Cassie from '../resources/Cassie.jpg';
import Generic from '../resources/GenericImage.jpg';

// Members card data containing image, name, title, office and description
export const membersDetails = [
  {
    image: Annette,
    name: "Annette O'Callaghan (Secretary)",
    title: 'Parliamentary Counsel',
    office: "New South Wales Parliamentary Counsel's Office",
    description:
      "Annette O'Callaghan was appointed NSW Parliamentary Counsel in 2018 and is the first woman to hold the role. From 2016 to 2018 she was the Queensland Parliamentary Counsel. She began her legislative drafting career in 1993 and has worked as a legislative drafter in New South Wales, Queensland and Victoria. Annette is the Secretary of PCC and runs the NSW office’s Government Bills Program. ",
  },
  {
    image: Generic,
    name: 'Jayne Atkins',
    title: 'Chief Parliamentary Counsel',
    office: 'Office of the Chief Parliamentary Counsel, Victoria',
    description:
      'Jayne Atkins was appointed Victorian Chief Parliamentary Counsel in January 2022. Jayne joined the Victorian Office of Chief Parliamentary Counsel in 1991 and was appointed Deputy Chief Parliamentary Counsel and Subordinate Legislation Manager in 2013, a role she held until her appointment as Chief Parliamentary Counsel.',
  },
  {
    image: Generic,
    name: 'Andrew Jones',
    title: 'Chief Parliamentary Counsel',
    office: 'Office of the Parliamentary Counsel, Northern Territory',
    description:
      'Andrew Jones was appointed Chief Parliamentary Counsel in the Northern Territory Office of the Parliamentary Counsel in February 2017. Andrew studied law and arts at Monash University and began practice in property law. Andrew began his drafting career at the Victorian Office of the Chief Parliamentary Counsel in August 2005. In addition to a decade of drafting experience in Victoria, Andrew drafted legislation and undertook advisory work in the United Kingdom Department for Work and Pensions and Department of Health. Andrew completed a Master of Laws at Monash University in 2014. Andrew is also a member of the Executive Leadership Team of the Department of the Chief Minister and Cabinet and is a graduate of the Australian Institute of Company Directors.',
  },

  {
    image: Keyes,
    name: 'Tony Keyes',
    title: 'Parliamentary Counsel',
    office: 'Office of the Queensland Parliamentary Counsel',
    description:
      'Tony Keyes was appointed Queensland Parliamentary Counsel in October 2018. Tony studied law and arts at the University of Queensland and was admitted as a solicitor of the Supreme Court of Queensland in 1990. Tony has had a long and varied legal career, including legal practice and policy work with the Criminal Justice Commission, the Ombudsman’s Office, the Law Reform Commission, the Department of the Premier and Cabinet and Crown Law.',
  },
  {
    image: Generic,
    name: 'Bianca Kimber',
    title: 'Parliamentary Counsel',
    office: "Australian Capital Territory Parliamentary Counsel's Office",
    description:
      'Bianca Kimber was appointed Parliamentary Counsel of the ACT Parliamentary Counsel’s Office in August 2021. Bianca previously held the role of Deputy Parliamentary Counsel for close to 5 years after 9 years as an Assistant Parliamentary Counsel in the ACT Parliamentary Counsel’s Office. Bianca studied law and arts (Asian Studies) at the Australian National University. ',
  },
  {
    image: Lawn,
    name: 'Geoff Lawn',
    title: 'Parliamentary Counsel',
    office: "Western Australia Parliamentary Counsel's Office",
    description:
      'Geoff Lawn was appointed West Australian Parliamentary Counsel in November 2015 after joining the office as Senior Parliamentary Counsel in 2009. Geoff began his legislative drafting career at the New Zealand Parliamentary Counsel’s Office in 1985, becoming Deputy Chief Parliamentary Counsel in 1997, and has also worked for the New Zealand Department of Justice and the New Zealand Law Commission. Geoff is currently the President of the Commonwealth Association of Legislative Counsel (CALC), having been elected at CALC’s ordinary general meeting in Livingstone, Zambia in April 2019. CALC is the peak body for legislative drafters in the Commonwealth and has over 2200 members drawn from 107 countries.',
  },
  {
    image: Generic,
    name: 'Meredith Leigh',
    title: 'First Parliamentary Counsel',
    office: 'Commonwealth Office of Parliamentary Counsel',
    description:
      'Meredith Leigh was appointed First Parliamentary Counsel in October 2021. Meredith holds a Bachelor of Arts, Bachelor of Laws and a Master of Laws and was admitted as a legal practitioner to the Supreme Court of NSW in 1997. Meredith joined the Office of Parliamentary Counsel in 1998 and served as Assistant Parliamentary Counsel, Senior Assistant Parliamentary Counsel and First Assistant Parliamentary Counsel before being appointed as Second Parliamentary Counsel in 2016.',
  },
  {
    image: Cassie,
    name: 'Cassie Nicholson',
    title: 'Chief Parliamentary Counsel',
    office: 'Parliamentary Counsel Office, New Zealand',
    description:
      "Cassie Nicholson was appointed Chief Parliamentary Counsel of the Parliamentary Counsel Office, New Zealand, in April 2021. Cassie previously held the role of Deputy Chief Parliamentary Counsel (Drafting) and has more than 20 years' experience at the Parliamentary Counsel Office. Cassie holds a BA/LLB (Hons) from Victoria University of Wellington, was a Commonwealth scholar, and holds an LLM from the London School of Economics and Political Science.",
  },

  {
    image: Travers,
    name: 'Aimee Travers',
    title: ' Parliamentary Counsel',
    office: 'South Australian Office of Parliamentary Counsel',
    description:
      'Aimee Travers was appointed South Australian Parliamentary Counsel in March 2016. Aimee  has worked in various positions in the South Australian Office of Parliamentary Counsel since 1993 and has drafted legislation in every area of law during that time, with extensive work within the Transport and Justice portfolios. Before becoming a legislative drafter, Aimee worked in general legal practice, primarily engaging in criminal defence work. Aimee has a Bachelor of Architectural Studies in addition to her legal qualifications.',
  },

  {
    image: Generic,
    name: 'Robyn Webb',
    title: 'Chief Parliamentary Counsel',
    office: 'Office of the Parliamentary Counsel, Tasmania',
    description: '',
  },
];

// Page Title of the member page
export const pageTitle = 'Membership of the Committee';

// Description text at the members page
export const memberDescriptionText =
  'The heads of the legislative drafting office of the Commonwealth, the States and Territories and New Zealand are members of PCC. The Committee meets several times a year to discuss proposed changes to national scheme legislation and share insights and advice about the management of drafting offices. The New South Wales Parliamentary Counsel, Annette O’Callaghan, is the Secretary of PCC. A junior drafter within the New South Wales Parliamentary Counsel’s Office provides secretariat support to PCC. A brief biography for the current members of PCC is set out below.';
