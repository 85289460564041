/* Content of the Home page of the application */

import CarouselImage7 from '../resources/carosuel7.png';
import CarouselImage8 from '../resources/carosuel8.png';

//  Image collection from the above imports
export const imageData = [
  { url: CarouselImage7, alt: 'Group picture' },
  { url: CarouselImage8, alt: 'Id cards image' },
];

// Page heading at the home page
export const pageTitle =
  "Welcome to the website of the Parliamentary Counsel's Committee";

// Introduction text at the home page
export const introText =
  "The Parliamentary Counsel's Committee (PCC) comprises the heads of Australian and New Zealand legislative drafting offices. The Committee coordinates the drafting of national uniform legislation that applies in Australian jurisdictions and New Zealand. In addition to providing a forum for the drafting of national scheme legislation, the Committee enables the heads of Australian and New Zealand drafting offices to share information about the preparation of legislation and the management of drafting offices.";
