/* Legislation component of the PCC website
 * Dependencies
 * Styling - Hyperlink.js, LegislationTab.js
 * Data - legislation.js
 */
import React, { useState, useEffect } from 'react';
import { tabHeader, legislationData } from '../data/Legislation';
import { LegislationHyperlink } from '../styles/Hyperlink';
import {
  Tabs,
  TabPanel,
  TabContainer,
  StyledTable,
  TH,
  Container,
  TD,
} from '../styles/LegislationTab';

// Main function of the Custom Legislation component
export default function CustomLegislationTab() {
  const [value, setValue] = useState(0);
  const [tabData, setTabData] = useState([]);
  const [active, setActive] = useState(0);

  // function to handle the click when tab is changed
  const handleClick = (event) => {
    const index = Number(event.target.id);
    if (index !== active) {
      setActive(index);
      setValue(index);
    }
  };

  // function to filter the database based on alphabets
  const filterByAlphaRange = (arrayData = [], indexValues) => {
    let rangeStart;
    let rangeEnd;
    switch (indexValues) {
      case 0:
        rangeStart = 'a';
        rangeEnd = 'c';
        break;
      case 1:
        rangeStart = 'd';
        rangeEnd = 'f';
        break;
      case 2:
        rangeStart = 'g';
        rangeEnd = 'i';
        break;
      case 3:
        rangeStart = 'j';
        rangeEnd = 'l';
        break;
      case 4:
        rangeStart = 'm';
        rangeEnd = 'o';
        break;
      case 5:
        rangeStart = 'p';
        rangeEnd = 'r';
        break;
      case 6:
        rangeStart = 's';
        rangeEnd = 'v';
        break;
      case 7:
        rangeStart = 'w';
        rangeEnd = 'z';
        break;
      default:
        rangeStart = 'a';
        rangeEnd = 'c';
    }

    const filtered = arrayData.filter((titleName) => {
      const [firsttTitleChar] = titleName.title.toLowerCase();
      return firsttTitleChar >= rangeStart && firsttTitleChar <= rangeEnd;
    });
    setTabData(filtered);
    return filtered;
  };

  useEffect(() => {
    filterByAlphaRange(legislationData, value);
  }, [value]);

  return (
    <div>
      <Tabs>
        {tabHeader.map((headerValue, index) => (
          <TabContainer
            onClick={handleClick}
            active={active === index}
            id={index}
            key={headerValue}
          >
            {headerValue}
          </TabContainer>
        ))}
      </Tabs>
      <TabPanel>
        <StyledTable>
          <tbody>
            <tr>
              <TH>Title</TH>
              <TH>Current Version</TH>
              <TH>Historical Version(s)</TH>
              <TH>Amendment(s)</TH>
            </tr>
            {tabData.map((contentData) => (
              <tr key={contentData.title}>
                <TD>
                  <b>{contentData.title}</b>
                </TD>
                <TD>
                  {contentData.current.map((currentVersion) => (
                    <Container>
                      <LegislationHyperlink
                        href={currentVersion.link}
                        target="_blank"
                      >
                        {currentVersion.hyperLinkText}
                      </LegislationHyperlink>
                      {currentVersion.blurb}
                    </Container>
                  ))}
                </TD>
                <TD>
                  {contentData.historical.map((historyVersion) => (
                    <Container>
                      <LegislationHyperlink
                        href={historyVersion.link}
                        target="_blank"
                      >
                        {historyVersion.hyperLinkText}
                      </LegislationHyperlink>
                      {historyVersion.blurb}
                      <br />
                    </Container>
                  ))}
                </TD>
                <TD>
                  {contentData.amendment.map((amendmentsVersion) => (
                    <Container>
                      <LegislationHyperlink
                        href={amendmentsVersion.link}
                        target="_blank"
                      >
                        {amendmentsVersion.hyperLinkText}
                      </LegislationHyperlink>
                      {amendmentsVersion.blurb}
                      <br />
                    </Container>
                  ))}
                </TD>
              </tr>
            ))}
          </tbody>
        </StyledTable>
      </TabPanel>
    </div>
  );
}
