/* Accessibility page of the application sharing details about site accessbility */
/* Dependencies
 * Components -  CustomFooter.jsx,
 * Styling - Text.js, Wrapper.js, Heading.js
 */
import React from 'react';
import CustomFooter from '../components/CustomFooter';
import { Wrapper } from '../styles/Wrapper';
import { Text } from '../styles/Text';
import { PageTitle, Heading } from '../styles/Heading';
import { ContactHyperlink } from '../styles/Hyperlink';
import {
  pageTitle,
  accessibilityIntro,
  webDesignHeading,
  webDesignTextParagraph1,
  webDesignTextParagraph2,
  standardComplianceHeading,
  standardComplianceText,
  convertingPDFHeading,
  convertingPDFTextParagraph1,
  convertingPDFTextParagraph2,
} from '../data/Accessibility';

export default function Copyright() {
  return (
    <>
      <Wrapper>
        <PageTitle>{pageTitle}</PageTitle>
        <Text>{accessibilityIntro}</Text>
        <Heading>{webDesignHeading}</Heading>
        <Text>{webDesignTextParagraph1}</Text>
        <Text>{webDesignTextParagraph2}</Text>
        <Heading>{standardComplianceHeading}</Heading>
        <Text>{standardComplianceText}</Text>
        <Heading>{convertingPDFHeading}</Heading>
        <Text>
          {convertingPDFTextParagraph1}
          <ContactHyperlink
            href="http://www.adobe.com/products/acrobat/access_onlinetools.html"
            target="_blank"
            rel="noreferrer"
          >
            http://www.adobe.com/products/acrobat/access_onlinetools.html
          </ContactHyperlink>
        </Text>
        <Text>{convertingPDFTextParagraph2}</Text>
      </Wrapper>
      <CustomFooter />
    </>
  );
}
