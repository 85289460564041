/* Styling for the publication component */
import styled from 'styled-components';

// divv to handle scaling icons inside accordion
// at Publication page
export const ScalingBar = styled.div`
  margin: 1rem;
  display: flex;
  justify-content: space-between;
`;

// Accordion styling for the publication page
export const AccordionStyle = styled.div`
  border: 0.125rem solid ${(props) => props.theme.tertiaryColor};
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  padding: 1rem;
  elevation: 2;
`;
