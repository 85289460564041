import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
	html {
		font-size: 16px;
		box-sizing: border-box;
	}	

	*, *:before, *:after {
		padding: 0;
		margin: 0;
		box-sizing: inherit;
	}

	body {
		font-family: ${(props) => props.theme.font};
		background-color: ${(props) => props.theme.background};
		color: ${(props) => props.theme.primaryColor};
		line-height: 1.5;
		overflow-x: hidden;
	}

	body::-webkit-scrollbar {
			width: 0.25rem;
	}

	body::-webkit-scrollbar-track {
		background: ${(props) => props.theme.background};
	}

	b{
		font-family: ${(props) => props.theme.font};
		font-weight: bold;
	}

	body::-webkit-scrollbar-thumb {
		background: ${(props) => props.theme.accentColor};
	}

	h1, h2, h3, h4, h5, h6 {
		font-weight: normal;
	}
	h1{
		font-family: ${(props) => props.theme.font}
		line-height: 1.8;
	}
	h2{
		font-size: 20px;
	}
	p{
		font-family: ${(props) => props.theme.font};
		line-height: 1.8;
		overflow-x: hidden;
		text-align: left;
	}
	a {
		text-decoration: none;
		color: inherit;
	}

	.upload-progress-bar {
		color: #FFF;
	}

	textarea {
		resize: none;
		overflow: hidden;
	}
`;
