/* Privacy page of the application displaying privacy statement of the organisation */
/* Dependencies
 * Components - CustomFooter.jsx
 * Styling  Wrapper.js, Heading.js, Text.js
 * Data - Privacy.js
 */

import React from 'react';
import CustomFooter from '../components/CustomFooter';
import { PageTitle } from '../styles/Heading';
import { Wrapper } from '../styles/Wrapper';
import { Text } from '../styles/Text';
import { privacyStatement, pageTitle, privacyActName } from '../data/Privacy';

export default function Privacy() {
  return (
    <>
      <Wrapper>
        <PageTitle>{pageTitle}</PageTitle>
        <Text>
          {privacyStatement} <i>{privacyActName}</i>
        </Text>
      </Wrapper>
      <CustomFooter />
    </>
  );
}
