/* Various Wrapper styled components used across the Project */
import styled from 'styled-components';

// Wrapper used generically
export const Wrapper = styled.div`
  border-left: 0.3rem solid ${(props) => props.theme.tertiaryColor};
  justify-content: center;
  padding: 0.5rem;
  padding-left: 1rem;
  min-height: 80vh;
  margin-top: 6.5%;

  @media screen and (max-width: 1400px) {
    margin-top: 9.8%;
  }

  @media screen and (max-width: 1110px) {
    border-left: none;
    margin-top: 0.1%;
  }
`;

// Wrapper used for footer component
export const FooterWrapper = styled.div`
  display: flex;
  border-left: 0.313rem solid ${(props) => props.theme.tertiaryColor};
  padding: 1rem;
  background-color: ${(props) => props.theme.accentColor};
  color: ${(props) => props.theme.secondaryColor};

  @media screen and (max-width: 700px) {
    margin-bottom: 3.5rem;
    border-left: none;
  }
`;

// Wrapper used forwrapping logo and title of the header component
export const LogoWrapper = styled.div`
  display: flex;
  width: 11.7%;
  align-items: center;
  padding: 1rem;
  border-right: 0.3rem solid ${(props) => props.theme.tertiaryColor};

  @media screen and (max-width: 1400px) {
    width: 17.3%;
    border-left: none;
    position: static;
  }

  @media screen and (max-width: 700px) {
    width: 40%;
    border-right: none;
    position: static;
  }
`;

// Wrapper used for header component
export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.accentColor};
  color: ${(props) => props.theme.secondaryColor};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;

  @media screen and (max-width: 700px) {
    border-left: none;
    position: static;
  }
`;

// Wrapper used for Tab component
export const TabWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  padding: 1;
`;

// Wrapper used timeline component
export const TimelineWrapper = styled.div`
  width: 100%;
  height: 90%;
  display: flex;
  justify-content: center;

  .timeline-card-content {
    display: flex;
    justify-content: center;
  }

  .css-1pcavt3-TimelineCardTitle {
    text-align: center;
    font-size: 18px;
    font-family: Arial, sans-serif;
    line-height: 1.85;
  }

  .css-1c6yclx-TimelineHorizontalWrapper{
    width:68%;
  }

  .css-1kwl3hd-TimelineContentDetailsWrapper.show-less {
    max-height: 120px;
    scrollbar-width: none;
    overflow: hidden;
  }
  
  .css-9rast5-TimelineItemContentWrapper {
    min-height: 80px;
    padding: 1rem;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .show-more {
    display: none;
  }

  @media screen and (max-width: 1400px) {
    .css-1c6yclx-TimelineHorizontalWrapper{
      width:48%;
    }
  }

  @media screen and (max-width: 700px) {
    .timeline-card-content {
      margin-left: 2rem;
    }

    .css-9rast5-TimelineItemContentWrapper {
      width: 90%;   
    }

    .css-1kwl3hd-TimelineContentDetailsWrapper.show-less {
      max-height: 350px;
      scrollbar-width: 1px;
    }
  }
  }
`;

// Wrapper used for PDF Viewer component
export const PDFWrapper = styled.div`
  border: 0.2rem solid ${(props) => props.theme.tertiaryColor};
  background-color: ${(props) => props.theme.accentColor};
`;

// Wrapper used for side bar navigation component
export const NavigationWrapper = styled.nav`
  width: 11.5%;
  position: fixed;
  display: flex;
  flex-direction: column;

  svg {
    width: 40px;
    height: 40px;
    margin-right: 1rem;
    color: ${(props) => props.theme.accentColor};
    margin-left: 1.5rem;
  }

  span {
    font-family: Arial, sans-serif;
    font-weight: bold;
    font-size: 16px;
    padding-right: 0.2rem;
  }

  .selected {
    border-left: 5px solid ${(props) => props.theme.accentColor};
  }
  ,
  .selected svg {
    color: ${(props) => props.theme.accentColor};
  }

  @media screen and (max-width: 1400px) {
    width: 17%;
  }

  @media screen and (max-width: 1110px) {
    width: 10%;

    span {
      display: none;
    }
  }

  @media screen and (max-width: 700px) {
    z-index: 1000;
    bottom: 0;
    width: 100%;
    justify-content: space-around;
    background: ${(props) => props.theme.background};
    display: flex;
    flex-direction: row;

    svg {
      margin-right: 0rem;
      width: 30px;
      height: 30px;
      margin-left: 0rem;
    }
    .selected {
      border-left: none;
      border-top: 2.5px solid ${(props) => props.theme.accentColor};
    }
    .selected svg {
      color: ${(props) => props.theme.accentColor};
    }
  }

  @media screen and (max-width: 530px) {
    height: 9%;
  }
`;

// Wrapper used for publication accordion component
export const PublicationWrapper = styled.div``;

// Sub Wrapper used for timeline component
export const TimelineSubWrapper = styled.div`
  width: 95%;
  height: 2%;
`;

// div holding instruction page infographic
export const InstructionSubWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;

  .TimelineEvent-barSimple-12 {
    background-color: #0b186d;
  }
`;

// Custom Map wrapper for the australian map component
export const MapWrapper = styled.div`
  .body {
    font-family: Arial, sans-serif;

    &__block {
      display: inline;

      &__map {
        display: inline-block;
        width: '100%';

        &--australia {
          width: 31.25rem;

          .svg-map__location {
            &--heat0 {
              fill: #4c7995;
            }

            &--heat1 {
              fill: #002664;
            }

            &--heat2 {
              fill: #0476a5;
            }

            &--heat3 {
              fill: #005ea4;
            }
            &--heat4 {
              fill: voilet;
            }

            &--heat5 {
              fill: amber;
            }

            &--heat6 {
              fill: #009456;
            }

            &--heat7 {
              fill: #0476a5;
            }
            &:focus,
            &:hover {
              opacity: 0.75;
            }
          }
        }
      }
    }
    @media screen and (max-width: 43.75rem) {
      &__block {
        display: inline;

        &__map {
          display: inline-block;
          width: '100%';

          &--australia {
            width: 100%;
          }
        }
      }
    }
  }
`;

// div holding home page text and carosuel
export const HomeSubWrapper = styled.div`
  display: flex;
  padding: 0.1rem;
  flex-direction: column;
`;

// div holding home page text and carosuel
export const HomePageImageWrapper = styled.div`
  display: flex;
  padding: 0.1rem;
  flex-direction: row;

  @media screen and (max-width: 530px) {
    flex-direction: column;
  }
`;

// div holding contact page content
export const ContactPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 3;
`;

// div holding contact details
export const ContactDetailsWrapper = styled.div`
  display: flex;
  align-items: center;
  line-height: 3;
`;
