/* Content of the Navigation panel component */

// link, text and ids of the various elements in side nav
const navigationData = [
  {
    id: 1,
    link: '/',
    text: 'Home',
  },
  {
    id: 2,
    link: '/about',
    text: 'About PCC',
  },
  {
    id: 3,
    link: '/members',
    text: 'Members',
  },
  {
    id: 4,
    link: '/legislations',
    text: 'National Uniform Legislation',
  },
  {
    id: 5,
    link: '/publications',
    text: 'Publications',
  },
  {
    id: 6,
    link: '/procedures',
    text: 'Instructing PCC',
  },
  {
    id: 7,
    link: '/relatedLinks',
    text: 'Related Links',
  },
  {
    id: 8,
    link: '/contact',
    text: 'Contact',
  },
];

export default navigationData;
