/* Publication Accordion component of the PCC website
 * Dependencies
 * Styling - Publication.js
 * External - Prop Types, react-icons
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { BiChevronDown } from 'react-icons/bi';
import { HiChevronUp } from 'react-icons/hi';
import { AccordionStyle } from '../styles/Publication';
import CustomPDFViewer from './CustomPDFReader';
import { PublicationWrapper } from '../styles/Wrapper';

// Main custom card function
// Taking title and pdflink as props value
export default function CustomPublicationAccordion(props) {
  const [isActive, setIsActive] = useState(false);
  const { title, pdfLink } = props;
  return (
    <PublicationWrapper>
      <AccordionStyle
        onClick={() => setIsActive(!isActive)}
        onKeyDown={() => setIsActive(!isActive)}
      >
        <b>{title}</b>
        {isActive ? <HiChevronUp size={25} /> : <BiChevronDown size={25} />}
      </AccordionStyle>
      {isActive && <CustomPDFViewer pdfLink={pdfLink} />}
    </PublicationWrapper>
  );
}

// Defining Proptypes
CustomPublicationAccordion.propTypes = {
  title: PropTypes.string,
  pdfLink: PropTypes.string,
};

// Assigning default values to props
CustomPublicationAccordion.defaultProps = {
  pdfLink: 'PDF Link',
  title: 'Title',
};
