/* Generic Project theme used across the project */
const blueTheme = {
  background: '#FFF',
  bgHover: '#F5F8FA',
  primaryColor: '#17141A',
  secondaryColor: '#FFF',
  accentColor: '#0b186d',
  tertiaryColor: '#CCD6DD',
  tertiaryColor2: '#F5F8FA',
  overlay: 'rgba(147, 149, 150, 0.4)',
  font: 'Arial,  sans-serif',
  bs1: '0 0 0.375rem 0.188rem rgba(0,0,0,0.1)',
};

export default blueTheme;
