/* Australian Map Component used in the related links page */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Australia from '@svg-maps/australia';
import { SVGMap } from 'react-svg-map';
import { MapWrapper } from '../styles/Wrapper';
import { getLocationId, getLocationName } from '../context/utils';

class CustomAustralianLinksMap extends Component {
  constructor(props) {
    super(props);

    // Linking locations ids with urls
    this.legislationLinks = {
      act: 'http://www.legislation.act.gov.au/',
      'nt-mainland': 'https://legislation.nt.gov.au/',
      'sa-mainland': 'http://www.legislation.sa.gov.au/index.aspx',
      'qld-mainland': 'https://www.legislation.qld.gov.au',
      'tas-mainland': 'http://www.legislation.tas.gov.au',
      nsw: 'http://www.legislation.nsw.gov.au',
      vic: 'http://www.legislation.vic.gov.au',
      wa: 'http://www.legislation.wa.gov.au',
    };

    this.gazettesLinks = {
      act: 'https://www.legislation.act.gov.au/Gazettes',
      'nt-mainland': 'https://nt.gov.au/about-government/gazettes',
      'sa-mainland': 'https://governmentgazette.sa.gov.au/',
      'qld-mainland': 'https://www.publications.qld.gov.au/group/gazettes-2020',
      'tas-mainland': 'http://www.gazette.tas.gov.au/',
      nsw: 'https://www.legislation.nsw.gov.au/gazette',
      vic: 'http://www.gazette.vic.gov.au/',
      wa: 'https://www.slp.wa.gov.au/gazette/gazette.nsf',
    };

    this.parliamentsLinks = {
      act: 'http://www.parliament.act.gov.au/',
      'nt-mainland': 'https://parliament.nt.gov.au',
      'sa-mainland': 'https://governmentgazette.sa.gov.au/',
      'qld-mainland': 'http://www.parliament.qld.gov.au/',
      'tas-mainland': 'http://www.parliament.tas.gov.au/',
      nsw: 'http://www.parliament.nsw.gov.au',
      vic: 'http://www.parliament.vic.gov.au/',
      wa: 'http://www.parliament.wa.gov.au/index.htm',
    };

    this.draftingOfficesLinks = {
      act: 'http://www.pco.act.gov.au/default.htm',
      'nt-mainland': 'https://parliament.nt.gov.au',
      'sa-mainland':
        'https://www.legislation.sa.gov.au/Web/Parliamentary%20Counsel%20guidelines/About%20Parliamentary%20Counsel/AboutParliamentaryCounsel.aspx',
      'qld-mainland': 'http://www.legislation.qld.gov.au',
      'tas-mainland': 'http://www.dpac.tas.gov.au/divisions/opc/',
      nsw: 'http://www.pco.nsw.gov.au',
      vic: 'http://www.ocpc.vic.gov.au',
      wa: 'https://department.justice.wa.gov.au/p/parliamentary_counsel.aspx',
    };

    this.courtLinks = {
      act: 'https://www.courts.act.gov.au/supreme/judgment',
      'nt-mainland': 'https://supremecourt.nt.gov.au/decisions',
      'sa-mainland': 'http://www.lawfoundation.net.au/judgments/sa',
      'qld-mainland': 'https://www.sclqld.org.au/caselaw',
      'tas-mainland':
        'http://www.lawfoundation.net.au/ljf/app/0B6E81B6E9BCBACDCA2570A7000E83E7.html',
      nsw: 'https://www.caselaw.nsw.gov.au/',
      vic: 'https://www.courts.vic.gov.au/court-system/transcripts-and-judgments/judgments-decisions-and-orders',
      wa: 'https://department.justice.wa.gov.au/p/parliamentary_counsel.aspx',
    };

    this.bills = {
      act: 'https://www.legislation.act.gov.au/Static/Bills/Bills.html',
      'nt-mainland':
        'https://legislation.nt.gov.au/en/LegislationPortal/Bills/By-Session',
      'sa-mainland': 'https://www.legislation.sa.gov.au/browseBills.aspx',
      'qld-mainland': 'https://www.legislation.qld.gov.au/browse/bills',
      'tas-mainland': 'https://www.parliament.tas.gov.au/Bills/MainBill.html',
      nsw: 'https://www.parliament.nsw.gov.au/bills/pages/current-bills.aspx',
      vic: 'https://www.parliament.vic.gov.au/legislation',
      wa: 'https://www.parliament.wa.gov.au/parliament/bills.nsf/WebCurrentBills?openview',
    };

    this.state = {
      pointedLocation: null,
      focusedLocation: null,
      clickedLocation: null,
    };

    this.handleLocationMouseOver = this.handleLocationMouseOver.bind(this);
    this.handleLocationMouseOut = this.handleLocationMouseOut.bind(this);
    this.handleLocationClick = this.handleLocationClick.bind(this);
    this.handleLocationFocus = this.handleLocationFocus.bind(this);
    this.handleLocationBlur = this.handleLocationBlur.bind(this);
  }

  handleLocationMouseOver(event) {
    const pointedLocation = getLocationName(event);
    this.setState({ pointedLocation });
  }

  handleLocationMouseOut() {
    this.setState({ pointedLocation: null });
  }

  handleLocationClick(event) {
    const { linkType } = this.props;
    const clickedLocation = getLocationName(event);
    const clickedLocationId = getLocationId(event);
    this.setState({ clickedLocation });
    switch (linkType) {
      case 'legislation':
        window.open(this.legislationLinks[clickedLocationId], '_blank');
        break;
      case 'drafting':
        window.open(this.draftingOfficesLinks[clickedLocationId], '_blank');
        break;
      case 'parliament':
        window.open(this.parliamentsLinks[clickedLocationId], '_blank');
        break;
      case 'gazette':
        window.open(this.gazettesLinks[clickedLocationId], '_blank');
        break;
      case 'courts':
        window.open(this.courtLinks[clickedLocationId], '_blank');
        break;
      case 'bills':
        window.open(this.bills[clickedLocationId], '_blank');
        break;
      default:
        window.open(this.legislationLinks[clickedLocationId], '_blank');
    }
  }

  handleLocationFocus(event) {
    const focusedLocation = getLocationName(event);
    this.setState({ focusedLocation });
  }

  handleLocationBlur() {
    this.setState({ focusedLocation: null });
  }

  getLocationClassName(location, index) {
    // Generate random heat map
    return `svg-map__location svg-map__location--heat${index % 7}`;
  }

  render() {
    return (
      <MapWrapper>
        <SVGMap
          className="body__block__map body__block__map--australia"
          map={Australia}
          locationClassName={this.getLocationClassName}
          type="link"
          onLocationMouseOver={this.handleLocationMouseOver}
          onLocationMouseOut={this.handleLocationMouseOut}
          onLocationClick={this.handleLocationClick}
          onLocationFocus={this.handleLocationFocus}
          onLocationBlur={this.handleLocationBlur}
        />
      </MapWrapper>
    );
  }
}

CustomAustralianLinksMap.propTypes = {
  linkType: PropTypes.string.isRequired,
};

export default CustomAustralianLinksMap;
