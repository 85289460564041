/* Content of the Contact page */

// Page title of the contact page
export const pageTitle = 'Contact PCC';

// contact page introduction
export const contactPageHeadingPart1 =
  'Please do not hesitate to contact the Secretariat of PCC at ';

// contact page introduction
export const contactPageHeadingPart2 = ' if you have any questions.';

// PCC official email
export const contactEmail = 'pcc@pco.nsw.gov.au';

// Annette Contact section
export const contactSectionTitle = 'Annette’s contact details are as follows:';

export const annettePhoneNumber = '61 2 9321 3333';

export const annetteEmail = 'annette.ocallaghan@pco.nsw.gov.au';

export const webMasterEmail = 'webmaster@pco.nsw.gov.au';
