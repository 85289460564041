/* Content of the privacy page */

// Text for the privacy statement
export const privacyStatement =
  "The NSW Parliamentary Counsel's Office respects your privacy. Any personal information provided to this Office will be handled in accordance with the ";

// Name of privacy Act
export const privacyActName =
  ' Privacy and Personal Information Protection Act 1998. ';

// Page title for the privacy page
export const pageTitle = 'Privacy';
