/* Frequently used related link by the PCC stakeholders */
/* Dependencies
 * Components - CustomHeader.jsx, CustomFooter.jsx, CustomLinkTab.jsx
 * Styling - Hyperlink.js, Wrapper.js, Heading.js, Grid.js, LinkTab.js, Icon.js
 * Data - RelatedLink.js
 * External - react-svg-map, react-icons
 */
import React, { useState } from 'react';
import 'react-svg-map/lib/index.css';
import { BiLink } from 'react-icons/bi';
import CustomFooter from '../components/CustomFooter';

import CustomLinkTab from '../components/CustomLinkTab';
import { PageTitle } from '../styles/Heading';
import { Wrapper } from '../styles/Wrapper';
import { tabOptions, otherTabOptions, pageTitle } from '../data/RelatedLink';
import {
  RelatedLinkContainer,
  RelatedLinkTabs,
  RelatedLinkHyperLinkContainer,
  RelatedLinkHyperlink,
  RelatedLinkTabContainer,
  Content,
} from '../styles/LinkTab';
import { relatedIconStyle } from '../styles/Icons';

// Main function of the related links
export default function RelatedLinks() {
  const [active, setActive] = useState(0);

  const handleClick = (event) => {
    const index = Number(event.target.id);
    if (index !== active) {
      setActive(index);
    }
  };

  return (
    <>
      <Wrapper>
        <PageTitle>{pageTitle}</PageTitle>
        <RelatedLinkContainer>
          <RelatedLinkTabs>
            {tabOptions.map((options, index) => (
              <RelatedLinkTabContainer
                onClick={handleClick}
                active={active === index}
                id={index}
                key={options}
              >
                {options}
              </RelatedLinkTabContainer>
            ))}
          </RelatedLinkTabs>
        </RelatedLinkContainer>
        <RelatedLinkContainer>
          <Content active={active === 0} value="0">
            <CustomLinkTab tabType="legislation" />
          </Content>
          <Content active={active === 1} value="1">
            <CustomLinkTab tabType="bills" />
          </Content>
          <Content active={active === 2} value="2">
            <CustomLinkTab tabType="gazette" />
          </Content>
          <Content active={active === 3} value="3">
            <CustomLinkTab tabType="parliament" />
          </Content>
          <Content active={active === 4} value="4">
            <CustomLinkTab tabType="courts" />
          </Content>
          <Content active={active === 5} value="5">
            <CustomLinkTab tabType="drafting" />
          </Content>
          <Content active={active === 6} value="6">
            {otherTabOptions.map((options) => (
              <RelatedLinkHyperLinkContainer key={options.id}>
                <BiLink size={35} style={relatedIconStyle} color="white" />
                <RelatedLinkHyperlink
                  href={options.link}
                  target="_blank"
                  rel="noreferrer"
                >
                  {options.title}
                </RelatedLinkHyperlink>
              </RelatedLinkHyperLinkContainer>
            ))}
          </Content>
        </RelatedLinkContainer>
      </Wrapper>
      <CustomFooter />
    </>
  );
}
