/* Content of the header component */

// Organisation Name in header
export const headerText = "Parliamentary Counsel's Committee";

// Link text to portal
export const portalText = 'Portal';

// Link to confluence page
export const portalLink = 'https://portal.pcc.gov.au/';
