/* Side Panel Navigation component of the PCC website
 * Dependencies
 * Styling - SideNavigation.js, Wrapper.js, Image.js
 * Resource - logo.svg
 * External - react-icons
 * Data - Navigation.js
 */

import React from 'react';
import { NavLink } from 'react-router-dom';
import { FiHome } from 'react-icons/fi';
import { HiUserGroup } from 'react-icons/hi';
import { ImBooks } from 'react-icons/im';
import { GoLaw } from 'react-icons/go';
import { GiAustralia } from 'react-icons/gi';
import { BiSupport, BiLink } from 'react-icons/bi';
import { VscServerProcess } from 'react-icons/vsc';
import NaviagtionData from '../data/Navigation';
import { navDesktopLink, navTabletLink } from '../styles/SideNavigation';
import { NavigationWrapper } from '../styles/Wrapper';

// function to render icons in the side bar
// Taking id as the input value
const renderIcon = (id) => {
  switch (id) {
    case 1:
      return <FiHome />;
    case 2:
      return <GiAustralia />;
    case 3:
      return <HiUserGroup />;
    case 4:
      return <GoLaw />;
    case 5:
      return <ImBooks />;
    case 6:
      return <VscServerProcess />;
    case 7:
      return <BiLink />;
    case 8:
      return <BiSupport />;
    default:
      return '';
  }
};

// Main function of the side panel navigation
export default function Navigation() {
  return (
    <NavigationWrapper>
      {NaviagtionData.map((data) => (
        <NavLink
          style={window.innerWidth > 1400 ? navDesktopLink : navTabletLink}
          key={data.id}
          exact
          activeClassName="selected"
          to={data.link}
        >
          {renderIcon(data.id)}
          <span>{data.text}</span>
        </NavLink>
      ))}
    </NavigationWrapper>
  );
}
