/* Instruction page of the application explaining the PCC drafting process */
/* Dependencies
 * Components - CustomFooter.jsx
 * Styling - Text.js, Wrapper.js, Heading.js
 * Data - Instruction.js
 * External - mailtop/horizontal-timeline, react-icons
 */
import React from 'react';
import { Timeline, TimelineEvent } from '@mailtop/horizontal-timeline';
import { SiOpenapiinitiative } from 'react-icons/si';
import { RiDraftLine } from 'react-icons/ri';
import { GiFountainPen } from 'react-icons/gi';
import { HiOutlineDocumentReport } from 'react-icons/hi';
import CustomFooter from '../components/CustomFooter';
import { Wrapper, InstructionSubWrapper } from '../styles/Wrapper';
import { Heading, PageTitle } from '../styles/Heading';
import { Text } from '../styles/Text';
import { procedureData, pageTitle } from '../data/Instruction';

// function to render colors of icons
// taking id from the data
function renderColor(id) {
  switch (id) {
    case 1:
      return '#1E90FF';
    case 2:
      return '#3CB371';
    case 3:
      return '#FFA500';
    case 4:
      return '#B22222';
    default:
      return 'Color';
  }
}

// function to render icons of the infographics
// taking id from the data
function renderIcon(id) {
  switch (id) {
    case 1:
      return SiOpenapiinitiative;
    case 2:
      return RiDraftLine;
    case 3:
      return GiFountainPen;
    case 4:
      return HiOutlineDocumentReport;
    default:
      return 'Icon';
  }
}

// Main function of the instruction page calling its components
export default function Procedures() {
  return (
    <>
      <Wrapper>
        <PageTitle>{pageTitle}</PageTitle>
        <InstructionSubWrapper>
          <Timeline
            maxEvent={4}
            placeholder
            variant={window.innerWidth < 650 ? 'simple' : 'default'}
            height={window.innerWidth < 700 ? '5rem' : '12rem'}
          >
            {procedureData.slice(0, 4).map((data) => (
              <TimelineEvent
                key={data.id}
                color={renderColor(data.id)}
                icon={renderIcon(data.id)}
                title={data.heading}
              />
            ))}
          </Timeline>
        </InstructionSubWrapper>
        <Heading style={{ textAlign: 'center', marginBottom: '1.5rem' }}>
          PCC Workflow
        </Heading>
        {procedureData.map((data) => (
          <div key={data.heading}>
            <Heading>{data.heading}</Heading>
            <Text>{data.description}</Text>
          </div>
        ))}
      </Wrapper>
      <CustomFooter />
    </>
  );
}
