/* Style class for the Related Link page and component */
import styled from 'styled-components';

// div to show/hide content in legislation Tab
export const Content = styled.div`
  ${(props) => (props.active ? '' : 'display:none')}
`;

// div to hold various link with icons
export const LinkConatiner = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem;
`;

// div to hold map component
export const MapContainer = styled.div`
  align-items: center;
  display: flex;
  padding: 1rem;
  padding-left: 5rem;
`;

// link style on the related link pages
export const Hyperlink = styled.a`
  margin: 1rem;
  fontsize: 2rem;

  @media screen and (max-width: 700px) {
    font-size: 0.6rem;
  }
`;

// Individual tab in vertical pane
// on the related links pages
export const Tabs = styled.div`
  overflow: hidden;
  height: 100%;
`;

// Vertical navigation button on the related link pages
export const TabContainer = styled.button`
  border: none;
  outline: none;
  cursor: pointer;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 1rem;
  padding-left: 2rem;
  font-size: 1rem;
  border-right: 2px solid ${(props) => (props.active ? '#f40052' : '#CCD6DD')};
  text-align: left;

  @media screen and (max-width: 700px) {
    font-size: 0.6rem;
  }
`;

// Individual div to hold horizontal navigation pane
export const RelatedLinkContainer = styled.div`
  width: 100%;
  justify-content: center;
  border-top: none;
  border: 1px solid #dee4e9;

  @media screen and (max-width: 700px) {
    width: 100%;
  }
`;

// div to hold horizontal navigation pane
export const RelatedLinkTabs = styled.div`
  overflow: hidden;
  height: 3em;
`;

// for other links tab holding link and icon together
export const RelatedLinkHyperLinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 2rem;
`;

// div for resizing the text size
export const RelatedLinkHyperlink = styled.div`
  margin-left: 1rem;
  font-size: 1rem;

  @media screen and (max-width: 700px) {
    font-size: 0.8rem;
  }
`;

// horizontal navigation button on the related link pages
export const RelatedLinkTabContainer = styled.button`
  border: none;
  outline: none;
  cursor: pointer;
  width: 14.28%;
  position: relative;
  background-color: ${(props) => props.theme.accentColor};
  font-size: 0.9rem;
  border-bottom: 2px solid ${(props) => (props.active ? '#f40052' : '')};
  background-color: ${(props) => props.theme.accentColor};
  height: 3rem;
  color: white;

  @media screen and (max-width: 700px) {
    font-size: 0.3rem;
    width: 14.28%;
  }
`;
