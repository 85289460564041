/* Style class for the Members page and component */
import styled from 'styled-components';

// Card design
export const Card = styled.div`
  width: 96%;
  height: 88%;
  display: flex;
  flex-direction: column;
  border: 2px solid ${(props) => props.theme.tertiaryColor};
  color: ${(props) => props.theme.secondaryColor};
`;

// Blue header part of card
export const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.accentColor};
  height: 20%;
  margin-bottom: 3rem;
`;

// Member's image in avatar
export const Image = styled.img`
  width: 9.063rem;
  height: 9.3rem;
  border-radius: 200px;
  border: 5px solid white;
  margin-top: 5rem;
`;

// div holding memeber's details like name,
// details, position
export const MemberDetails = styled.h1`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  color: black;
  font-size: 1.125rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 0.5rem;
`;

// Custom style for the title text
export const Title = styled.p`
  margin-top: 0.25rem;
  text-align: center;
  font-size: 0.938rem;
`;

// Custom style for the description text
export const Description = styled.p`
  margin-top: 1.5rem;
  text-align: left;
  font-size: 0.875rem;
  font-weight: normal;
  margin-bottom: 1rem;
`;
