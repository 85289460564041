/* Styling for the side navigation components */
import styled from 'styled-components';

// container holding the PCC logo in side nav
export const LogoContainer = styled.div`
  @media screen and (max-width: 700px) {
    display: none;
  }
`;

// Styling for the option in the side nav
export const linkStyle = {
  background: '#0b186d',
  display: 'flex',
  justifyContent: 'center',
};

// styling used by desktops/laptops for side nav
export const navDesktopLink = {
  height: '6rem',
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
};

// styling used by mobile devices for side nav
export const navTabletLink = {
  height: '5rem',
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
};
