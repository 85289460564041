/* Content of the Related links tab component */

// Data about the various legislation across
// Australia and other nations
export const legislationData = [
  {
    id: 1,
    index: 1,
    link: 'https://laws-lois.justice.gc.ca/eng/',
    text: 'Department of Justice',
  },
  {
    id: 2,
    index: 1,
    link: 'http://www.canlii.org/en/index.php',
    text: 'CanLII',
  },
  {
    id: 3,
    index: 1,
    link: 'https://ontario.ca/laws',
    text: 'Ontario E-Laws',
  },
  {
    id: 4,
    index: 2,
    link: 'http://www.irishstatutebook.ie/',
    text: 'Irish Statute Book (Office of the Attorney General) 1922',
  },
  {
    id: 5,
    index: 2,
    link: 'https://www.oireachtas.ie/en/bills/',
    text: 'Houses of the Oireachtas',
  },
  {
    id: 6,
    index: 2,
    link: 'http://www.bailii.org/ie/legis/num_act/',
    text: 'Irish Legislation BAILII',
  },
  {
    id: 7,
    index: 2,
    link: 'https://www.ucc.ie/academic/law/irlii/index.php',
    text: 'IRLII',
  },
  {
    id: 8,
    index: 3,
    link: 'http://www.legislation.govt.nz/default.aspx',
    text: 'New Zealand Legislation',
  },
  {
    id: 9,
    index: 4,
    link: 'http://www.legislation.govt.nz/default.aspx',
    text: 'Singapore Statutes Online',
  },
  {
    id: 10,
    index: 5,
    link: 'http://www.gov.za/documents/acts',
    text: 'South African Government Information',
  },
  {
    id: 11,
    index: 5,
    link: 'http://www.justice.gov.za/legislation/legislation_main.htm',
    text: 'Department of Justice and Constitutional Development',
  },
  {
    id: 12,
    index: 5,
    link: 'http://www.saflii.org/',
    text: 'SAFLII',
  },
  {
    id: 13,
    index: 6,
    link: 'http://www.legislation.gov.uk/',
    text: 'Legislation.gov.uk',
  },
  {
    id: 14,
    index: 6,
    link: 'https://www.law.cornell.edu/uscode/text',
    text: 'UK Legislation BAILII',
  },
  {
    id: 15,
    index: 7,
    link: 'http://www.bailii.org/uk/legis/num_act/',
    text: 'U.S. Code LII at Cornell University Law School',
  },
  {
    id: 16,
    index: 7,
    link: 'https://www.congress.gov/',
    text: 'Library of Congress',
  },
  {
    id: 17,
    index: 7,
    link: 'https://www.govinfo.gov/',
    text: 'Government Publishing Office (US)',
  },
  {
    id: 18,
    index: 7,
    link: 'https://www.thecre.com/fedlaw/default.htm',
    text: 'FedLaw',
  },
  {
    id: 19,
    index: 8,
    link: 'https://www.legislation.gov.au/',
    text: 'Federal Register of Legislation',
  },
  {
    id: 20,
    index: 9,
    link: 'http://www.austlii.edu.au/databases.html',
    text: 'AustLII database',
  },
  {
    id: 21,
    index: 9,
    link: 'http://www.commonlii.org/',
    text: 'CommonLII',
  },
];

// Data about the various courts across
// Australia and other nations
export const courtData = [
  {
    id: 1,
    index: 1,
    link: 'https://scc-csc.lexum.com/scc-csc/en/nav.do',
    text: 'Canada',
  },
  {
    id: 2,
    index: 2,
    link: 'https://www.judiciary.hk/en/judgments_legal_reference/judgments.html',
    text: 'Hong Kong',
  },
  {
    id: 3,
    index: 3,
    link: 'https://www.courts.ie/judgments',
    text: 'Ireland',
  },
  {
    id: 4,
    index: 4,
    link: 'https://www.justice.govt.nz/courts/decisions/',
    text: 'New Zealand',
  },
  {
    id: 5,
    index: 5,
    link: 'https://www.lawnet.sg/lawnet/web/lawnet/free-resources/',
    text: 'Singapore',
  },
  {
    id: 6,
    index: 6,
    link: 'http://www.justice.gov.za/sca/judgments/judgem_sca.htm',
    text: 'South Africa',
  },
  {
    id: 7,
    index: 7,
    link: 'http://www.bailii.org/uk/cases/UKSC/',
    text: 'Supreme Court 2009+',
  },
  {
    id: 8,
    index: 7,
    link: ' https://publications.parliament.uk/pa/ld/ldjudgmt.htm',
    text: 'House of Lords 1996 - 2009',
  },
  {
    id: 9,
    index: 7,
    link: 'http://www.scotcourts.gov.uk/home',
    text: 'Scottish Courts 1998+',
  },
  {
    id: 10,
    index: 8,
    link: 'https://www.law.cornell.edu/supremecourt/text',
    text: 'Supreme Court Decisions 1990 +',
  },
  {
    id: 11,
    index: 8,
    link: 'https://caselaw.findlaw.com/court/us-supreme-court',
    text: 'Supreme Court Decisions 1760+',
  },
  {
    id: 12,
    index: 8,
    link: 'https://www.supremecourt.gov/opinions/USReports.aspx',
    text: 'Supreme Court Opinions. Bound volumes 1991 +',
  },
  {
    id: 13,
    index: 9,
    link: 'https://eresources.hcourt.gov.au/',
    text: 'High Court of Australia',
  },
  {
    id: 14,
    index: 9,
    link: 'http://www.familycourt.gov.au/wps/wcm/connect/fcoaweb/judgments/full-court-judgments',
    text: 'Family Court of Australia',
  },
  {
    id: 15,
    index: 9,
    link: 'https://www.fedcourt.gov.au/digital-law-library/judgments/search',
    text: 'Federal Court of Australia',
  },
  {
    id: 16,
    index: 9,
    link: 'http://www.federalcircuitcourt.gov.au/wps/wcm/connect/fccweb/judgments/',
    text: 'Federal Circuit Court of Australia',
  },
];

// Data about the various bills website across
// Australia and other nations
export const BillsData = [
  {
    id: 1,
    index: 1,
    link: 'http://www.parl.ca/LegisInfo/Home.aspx?Language=E&ParliamentSession=42-1',
    text: 'Parliament of Canada',
  },
  {
    id: 2,
    index: 2,
    link: 'https://www.oireachtas.ie/en/bills/',
    text: 'Ireland (Houses of the Oireachtas)',
  },
  {
    id: 3,
    index: 3,
    link: 'https://www.legislation.govt.nz/default.aspx',
    text: 'New Zealand Legislation',
  },
  {
    id: 4,
    index: 4,
    link: 'https://www.parliament.gov.sg/',
    text: 'Parliament of Singapore',
  },
  {
    id: 5,
    index: 5,
    link: 'https://www.parliament.gov.za/',
    text: 'Parliament of the Republic of South Africa',
  },
  {
    id: 6,
    index: 6,
    link: 'https://bills.parliament.uk/',
    text: 'United Kingdom Parliament',
  },
  {
    id: 7,
    index: 7,
    link: 'https://www.congress.gov/',
    text: 'House of Representatives and Senate',
  },
  {
    id: 8,
    index: 7,
    link: 'https://www.senate.gov/pagelayout/legislative/b_three_sections_with_teasers/active_leg_page.htm',
    text: 'U.S. Senate: Active Legislation',
  },
  {
    id: 9,
    index: 7,
    link: 'https://www.govinfo.gov/app/collection/HOB',
    text: 'Government Publishing Office',
  },
  {
    id: 10,
    index: 8,
    link: 'http://www.parliament.scot/parliamentarybusiness/bills.aspx',
    text: 'The Scottish Parliament',
  },
];

// Data about the various Gazette across
// Australia and overseas
export const gazetteData = [
  {
    id: 1,
    index: 1,
    link: 'http://www-personal.umich.edu/~graceyor/govdocs/gazettes',
    text: 'Government Gazettes Online (International)',
  },
  {
    id: 2,
    index: 1,
    link: 'https://gazette.govt.nz/',
    text: 'New Zealand Gazette',
  },
];

// Data about the various parliaments across
// Australia and other nations
export const parliamentData = [
  {
    id: 1,
    index: 1,
    link: 'https://www.parliament.nz/en/',
    text: 'New Zealand Parliament',
  },
  {
    id: 2,
    index: 1,
    link: 'https://www.parliament.nz/en/pb/hansard-debates',
    text: 'New Zealand Hansard',
  },
  {
    id: 3,
    index: 2,
    link: 'https://www.parliament.uk/',
    text: 'United Kingdom Parliament',
  },
  {
    id: 4,
    index: 2,
    link: 'https://www.parliament.uk/business/publications/',
    text: 'United Kingdom Publications (Parliamentary Papers)',
  },

  {
    id: 7,
    index: 3,
    link: 'https://www.senate.gov/',
    text: 'Senate',
  },
  {
    id: 8,
    index: 3,
    link: 'https://www.house.gov/',
    text: 'House of Representatives',
  },
  {
    id: 9,
    index: 4,
    link: 'https://data.ipu.org/',
    text: 'Websites of National Parliaments',
  },
  {
    id: 10,
    index: 4,
    link: 'http://www.worldlii.org/catalog/2047.html',
    text: 'WorldLII: Parliaments',
  },
];

export const draftingOrganisationtData = [
  {
    id: 1,
    index: 1,
    link: 'https://pcc.gov.au/',
    text: "Australasian Parliamentary Counsel's Committee (PCC)",
  },
  {
    id: 2,
    index: 1,
    link: 'http://www.calc.ngo/',
    text: 'Commonwealth Association of Legislative Counsel (CALC)',
  },
  {
    id: 3,
    index: 2,
    link: 'http://www.pco.govt.nz/',
    text: 'New Zealand Parliamentary Counsel Office',
  },
];

// Options in the vertical tab of related links legislation Tab
export const legislationVerticalTabData = [
  'Australia',
  'Canada',
  'Ireland',
  'New Zealand',
  'Singapore',
  'South Africa',
  'United Kingdom',
  'U.S',
  'Commonwealth legislation',
  'General legislative database',
];

// Options in the vertical tab of related links Court Tab
export const courtVerticalTabData = [
  'Australia',
  'Canada',
  'Hong Kong',
  'Ireland',
  'New Zealand',
  'Singapore',
  'South Africa ',
  'United Kingdom ',
  'United States of America',
  'Commonwealth court judgements',
];

// Options in the vertical tab of related links parliament Tab
export const parliamentVerticalTabData = [
  'Australia',
  'New Zealand',
  'United Kingdom',
  'U.S',
  'Other',
];

// Options in the vertical tab of related links bills Tab
export const billsVerticalTabData = [
  'Australia',
  'Canada',
  'Ireland',
  'New Zealand',
  'Singapore',
  'South Africa',
  'United Kingdom',
  'U.S',
  'Scottland',
];

// Options in the vertical tab of related links gazette Tab
export const gazetteVerticalTabData = ['Australia', 'Overseas Gazettes'];

// Options in the vertical tab of related links drafting Tab
export const draftingVerticalTabData = [
  'Australia',
  'Australasian drafting organisations',
  'Overseas Drafting Office',
];

// Horizontal tab option on related links page
export const tabOptions = [
  'Legislation',
  'Bills',
  'Government Gazettes',
  'Parliaments',
  'Court Judgments',
  'Drafting Organisations',
  'Other',
];

// Options in the horizontal tab of related links Other Tab
export const otherTabOptions = [
  {
    id: 1,
    title: 'Council for the Australian Federation (CAF)',
    link: 'https://www.caf.gov.au/',
  },
  {
    id: 2,
    title: 'Council of Australian Governments (COAG)',
    link: 'https://www.coag.gov.au/',
  },
  {
    id: 3,
    title: 'Council of Attorneys-General',
    link: 'https://www.ag.gov.au/about-us/who-we-are/committees-and-councils/meeting-attorneys-general',
  },
  {
    id: 4,
    title: 'NSW Justice',
    link: 'https://www.justice.nsw.gov.au/',
  },
  {
    id: 5,
    title: 'NSW Government Directory',
    link: 'https://www.service.nsw.gov.au/nswgovdirectory',
  },
];

// Page title for the related links
export const pageTitle = 'Frequently used Related Links';
