/* Copyright page of the application sharing copyright and discalimer details */
/* Dependencies
 * Components - CustomFooter.jsx, CustomTimeLine.jsx
 * Styling - Text.js, Wrapper.js, Heading.js, Hyperlink.js
 * Data - Copyright.js
 */
import React from 'react';
import CustomFooter from '../components/CustomFooter';
import { Wrapper } from '../styles/Wrapper';
import { Heading, PageTitle } from '../styles/Heading';
import { Text } from '../styles/Text';
import { Hyperlink } from '../styles/Hyperlink';
import {
  copyrightHeading,
  disclaimerHeading,
  disclaimerText,
  pageTitle,
} from '../data/Copyright';

// Main function of the copyright calling its components
export default function Copyright() {
  return (
    <>
      <Wrapper>
        <PageTitle>{pageTitle}</PageTitle>
        <Heading>{copyrightHeading}</Heading>
        <Text>
          Unless otherwise noted, all content on the PCC website is provided
          under the{' '}
          <a href="www">
            Creative Commons Attribution 4.0 International (CC BY 4.0){' '}
          </a>
          licence.
        </Text>
        <Text>
          This licence allows you to share, copy and redistribute content
          published on the PCC website in any medium or format.
        </Text>
        <Text>
          The licence also allows you to adapt, transform and build upon content
          for any purpose, including commercial purposes.
        </Text>
        <Text>
          You may use content published on the PCC website subject to the
          following conditions:
        </Text>
        <ul style={{ marginLeft: '2rem', marginBottom: '1rem' }}>
          <li>
            <Text>
              Including a link to the relevant page of the website or, if using
              multiple pages from the website, a link to the PCC home page, and
            </Text>
          </li>
          <li>
            <Text>
              Attributing the content to the PCC website and inclusion a link to
              the Creative Commons licence, and
            </Text>
          </li>
          <li>
            <Text>
              Indicating if you have made any changes to content reproduced from
              the PCC website.
            </Text>
          </li>
        </ul>
        <Text>
          Please use either of the following statements to attribute content to
          the PCC website.
        </Text>
        <ul style={{ marginLeft: '2rem', marginBottom: '1rem' }}>
          <li>
            <Text>
              If the content has been changed: “Based on content from the PCC
              website,{' '}
              <Hyperlink href="www.pcc.gov.au" target="_blank">
                www.pcc.gov.au
              </Hyperlink>
              , at [full date of download].”
            </Text>
          </li>
          <li>
            <Text>
              If the content has not been changed: “Sourced from the PCC
              website,{' '}
              <Hyperlink href="www.pcc.gov.au" target="_blank">
                www.pcc.gov.au
              </Hyperlink>
              , at [full date of download].”
            </Text>
          </li>
        </ul>
        <Text>
          If material is identified as the copyright of a third party, the
          copyright owner’s permission is required to use the material. Using
          the material without the copyright owner’s permission may breach the
          Copyright Act 1968 of the Commonwealth.
        </Text>

        <Heading>{disclaimerHeading}</Heading>
        <Text>{disclaimerText}</Text>
      </Wrapper>
      <CustomFooter />
    </>
  );
}
