/* About page of the application sharing details about PCC and its history */
/* Dependencies
 * Components -  CustomFooter.jsx, CustomTimeLine.jsx
 * Styling - Text.js, Wrapper.js, Heading.js
 * Data - About.js
 */
import React from 'react';
import CustomFooter from '../components/CustomFooter';
import CustomTimeLine from '../components/CustomTimeLine';
import { Text } from '../styles/Text';
import { Wrapper } from '../styles/Wrapper';
import { Heading, PageTitle } from '../styles/Heading';
import {
  doHeading,
  historyHeading,
  pageTitle,
  whatWeDoText,
} from '../data/About';

// About page main function calling components
export default function About() {
  return (
    <>
      <Wrapper>
        <PageTitle>{pageTitle}</PageTitle>
        <Heading>{doHeading}</Heading>
        <Text>{whatWeDoText}</Text>
        <Heading>{historyHeading}</Heading>
        <CustomTimeLine />
      </Wrapper>
      <CustomFooter />
    </>
  );
}
