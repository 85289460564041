/* Publication page of the application featuring key publications of PCC */
/* Dependencies
 * Components - CustomHeader.jsx, CustomFooter.jsx, CustomPublicationAccordion.jsx
 * Styling  Wrapper.js, Heading.js
 * Data - Publication.js
 */

import React from 'react';
import CustomAccordion from '../components/CustomPublicationAccordion';
import CustomFooter from '../components/CustomFooter';
import { PageTitle } from '../styles/Heading';
import { Wrapper } from '../styles/Wrapper';
import { publicationAccordionData, pageTitle } from '../data/Publication';

// Main function of the publication page calling its components
export default function Publication() {
  return (
    <>
      <Wrapper>
        <PageTitle>{pageTitle}</PageTitle>
        {publicationAccordionData.map((data) => (
          <CustomAccordion
            key={data.pdfLink}
            pdfLink={data.pdfLink}
            title={data.heading}
          />
        ))}
      </Wrapper>
      <CustomFooter />
    </>
  );
}
