/* PDF rendering component of the PCC website
 * Dependencies
 * Styling - Publication.js. Wrapper.js, Text.js
 * External - Prop Types, react-icons, mgr-pdf-viewer-react
 */
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import PDFViewer from 'mgr-pdf-viewer-react';
import { FiZoomIn, FiZoomOut } from 'react-icons/fi';
import { ScalingBar } from '../styles/Publication';
import { PDFWrapper } from '../styles/Wrapper';
import { Text } from '../styles/Text';

// Main PDF rendering function
// taking pdf link as prop input from publication page
export default function CustomPDFReader(props) {
  const { pdfLink } = props;
  const [scale, setScale] = useState(window.innerWidth > 700 ? 1.0 : 0.4);

  return (
    <PDFWrapper>
      <ScalingBar>
        <FiZoomOut
          size={25}
          color="white"
          onClick={() => setScale(Number((scale - 0.1).toFixed(1)))}
        />
        <Text style={{ color: 'white' }}>Scale: {scale}</Text>
        <FiZoomIn
          size={25}
          color="white"
          onClick={() => setScale(Number((scale + 0.1).toFixed(1)))}
        />
      </ScalingBar>
      <PDFViewer
        document={{
          url: pdfLink,
        }}
        scale={scale}
      />
    </PDFWrapper>
  );
}

// Proptype definition for pdf link
CustomPDFReader.propTypes = {
  pdfLink: PropTypes.string.isRequired,
};
