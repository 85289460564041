/* Style class for the legislation page and component */
import styled from 'styled-components';

// div to show/hide content in legislation Tab
export const Content = styled.div`
  ${(props) => (props.active ? '' : 'display:none')}
  padding: 1rem;
`;

// div to handle individual block in blue
// navigation bar
export const Tabs = styled.div`
  overflow: hidden;
  background: #fff;
  height: 3em;
`;

// tab panel for the legislation page
export const TabPanel = styled.div`
  ${(props) => (props.active ? '' : 'display:none')}
  border: 0.1rem solid ${(props) => props.theme.tertiaryColor};
  border-top: none;
`;

// div to handle the top blue bar in legislation
// navigation bar
export const TabContainer = styled.button`
  border: none;
  outline: none;
  cursor: pointer;
  width: 12.5%;
  position: relative;
  font-size: 1rem;
  border-bottom: 0.2rem solid ${(props) => (props.active ? '#f40052' : '')};
  background-color: ${(props) => props.theme.accentColor};
  height: 3rem;
  color: white;

  @media screen and (max-width: 700px) {
    font-size: 0.6rem;
  }
`;

// style for the links used in content
export const hyperlinkStyle = {
  fontSize: '0.9rem',
  color: '#0b186d',
};

export const StyledTable = styled.table`
  text-align: center;
  font-family: Arial, sans-serif;
  border-collapse: collapse;
  border: 0.2rem solid #ddd;
  height: 100%;
  width: 100%;
  margin-bottom: 2rem;
`;

export const THead = styled.thead`
  border: 0.1rem solid #ddd;
  padding: 8px;
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #4caf50;
  color: white;
`;

export const TR = styled.tr`
  // custom css goes here
`;

export const TH = styled.th`
  border: 0.1rem solid #ddd;
  padding: 1rem;
  width: 25%;
  font-size: 1.1rem;
  text-align: center;
  padding-left: 1rem;
  font-weight: bold;

  @media screen and (max-width: 700px) {
    font-size: 0.5rem;
    width: 0.8%;
  }
`;

export const TD = styled.td`
  border: 0.1rem solid #ddd;
  padding: 1rem;
  width: 25%;
  text-align: left;
  padding-left: 1rem;

  @media screen and (max-width: 700px) {
    font-size: 0.5rem;
    width: 0.8%;
  }
`;

export const Container = styled.div`
  margin-bottom: 1rem;
  @media screen and (max-width: 700px) {
  }
`;
