/* Starting point for the application */
import React from 'react';
import { render } from 'react-dom';
import App from './main/App';
import { ThemeProvider } from './context/ThemeContext';

function RootApp() {
  return (
    <ThemeProvider>
      <App />
    </ThemeProvider>
  );
}

render(<RootApp />, document.getElementById('root'));
