/* Legislation component of the PCC website
 * Dependencies
 * Styling - LinkTab.js, Icons.js, Wrapper.js
 * External - Prop types, react-icons
 * Data - RelatedLink.js
 */

import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { BiLink } from 'react-icons/bi';
import { GiIsland } from 'react-icons/gi';
import CustomAustraliaLinkMap from './CustomAustralianLinksMap';
import {
  legislationData,
  courtData,
  BillsData,
  gazetteData,
  parliamentData,
  draftingOrganisationtData,
  legislationVerticalTabData,
  courtVerticalTabData,
  parliamentVerticalTabData,
  billsVerticalTabData,
  gazetteVerticalTabData,
  draftingVerticalTabData,
} from '../data/RelatedLink';
import {
  Tabs,
  TabContainer,
  Content,
  MapContainer,
  LinkConatiner,
  Hyperlink,
} from '../styles/LinkTab';
import { linkTabIconStyle } from '../styles/Icons';
import { TabWrapper } from '../styles/Wrapper';

// Main function of the Custom Link Tab
export default function CustomLinkTab(props) {
  const [value, setValue] = useState(0);
  const [active, setActive] = useState(0);
  const [tabData, setTabData] = useState([]);
  const [tabOptions, setTabOptions] = useState([]);
  const { tabType } = props;

  // function to handle tab change taking events as an input
  const handleClick = (event) => {
    const index = Number(event.target.id);
    setValue(index);
    if (index !== active) {
      setActive(index);
    }
  };

  // function to filter data based on tab selection
  const filterByTabType = (data = [], tabIndex) => {
    const filtered = data.filter((content) => content.index === tabIndex);
    setTabData(filtered);
    return filtered;
  };

  useEffect(() => {
    // switch to select appropriate data to display
    switch (tabType) {
      case 'legislation':
        setTabOptions(legislationVerticalTabData);
        filterByTabType(legislationData, value);
        break;
      case 'drafting':
        setTabOptions(draftingVerticalTabData);
        filterByTabType(draftingOrganisationtData, value);
        break;
      case 'bills':
        setTabOptions(billsVerticalTabData);
        filterByTabType(BillsData, value);
        break;
      case 'gazette':
        setTabOptions(gazetteVerticalTabData);
        filterByTabType(gazetteData, value);
        break;
      case 'courts':
        setTabOptions(courtVerticalTabData);
        filterByTabType(courtData, value);
        break;
      case 'parliament':
        setTabOptions(parliamentVerticalTabData);
        filterByTabType(parliamentData, value);
        break;
      default:
        setTabOptions(legislationVerticalTabData);
        filterByTabType(legislationData, value);
    }
  }, [tabType, value]);

  return (
    <TabWrapper>
      <Tabs>
        {tabOptions.map((verticalTabData, index) => (
          <TabContainer
            onClick={handleClick}
            active={active === index}
            id={index}
            key={verticalTabData}
          >
            {verticalTabData}
          </TabContainer>
        ))}
      </Tabs>
      {active === 0 ? (
        <Content active={active === 0} index={0}>
          <MapContainer>
            <CustomAustraliaLinkMap linkType={tabType} />
            <a
              href="http://www.norfolkisland.gov.nf/"
              target="_blank"
              rel="noreferrer"
              style={{ marginLeft: '20px' }}
            >
              <GiIsland color="#0b186d" size={30} />
            </a>
          </MapContainer>
        </Content>
      ) : (
        <Content active={active === value} index={value}>
          {tabData.map((data) => (
            <LinkConatiner key={data.id}>
              <BiLink size={35} style={linkTabIconStyle} color="white" />
              <Hyperlink href={data.link} target="_blank" rel="noreferrer">
                {data.text}
              </Hyperlink>
            </LinkConatiner>
          ))}
        </Content>
      )}
    </TabWrapper>
  );
}

// Defining prop type
CustomLinkTab.propTypes = {
  tabType: PropTypes.string.isRequired,
};
