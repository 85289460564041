/* Content of the Instruction page */

// Various steps involved in instructing PCC
export const procedureData = [
  {
    id: 1,
    heading: '1. Initiation of project',
    description:
      'PCC projects may be referred by a National Cabinet Reform Committee, a Ministerial Council or national governmental body or initiated by a member of the Committee. The instructions should clearly state the intended policy, provide information about timing, and include the instructor’s contact details.',
  },
  {
    id: 2,
    heading: '2. Allocation of work',
    description:
      'A jurisdictional drafting office and drafter are allocated as soon as possible after instructions are received.',
  },
  {
    id: 3,
    heading: '3. Drafting process',
    description:
      'Copies of initial and subsequent drafting instructions and all drafts are circulated to PCC to ensure members are aware of current projects and to assist with the development of the draft legislation.PCC members are invited to provide formal comments on a draft once it is close to being settled. Once all PCC comments have been addressed and the instructor has approved the draft, the draft is submitted to the PCC Secretary for final approval. The final draft and a PCC report is sent to the instructor as evidence of approval.',
  },
  {
    id: 4,
    heading: '4. PCC Report',
    description:
      'When a draft is settled by the PCC, a formal report, with the draft attached, is provided to the Ministerial Council or other body that requested the legislation. The report may draw attention to any matter of concern to the PCC.',
  },
  {
    id: 5,
    heading: 'Timing',
    description:
      'It is desirable that the lead instructor keep the lead drafter informed of the priority of the particular exercise. While all reasonable efforts will be made to settle drafts within the desired timetable, the contingencies of parliamentary programs and the timing of peak work periods may not make this possible.',
  },
  {
    id: 6,
    heading: 'Confidentiality',
    description:
      'PCC treats its work with the same high level of confidentiality that applies to the ordinary work of Parliamentary Counsel. Instructions to Parliamentary Counsel, and drafts or other advice provided by Parliamentary Counsel, are subject to legal professional privilege and that privilege is not waived by the circulation of instructions, drafts or other advice among PCC members.',
  },
];

// Page title of the instruction page
export const pageTitle = 'Instructing PCC';
