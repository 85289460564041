/* Member page of the application portraiting all the member of PCC */
/* Dependencies
 * Components -  CustomMemberCard.jsx
 * Styling - Text.js, Wrapper.js, Heading.js, Grid.js
 * Data - Member.js
 */
import React from 'react';
import CustomFooter from '../components/CustomFooter';
import CustomMemberCard from '../components/CustomMemberCard';
import { PageTitle } from '../styles/Heading';
import { Wrapper } from '../styles/Wrapper';
import Grid from '../styles/Grid';
import { Text } from '../styles/Text';
import {
  membersDetails,
  pageTitle,
  memberDescriptionText,
} from '../data/Member';

// Main function function of the member page calling its components
export default function Members() {
  return (
    <>
      <Wrapper>
        <PageTitle>{pageTitle}</PageTitle>
        <Text>{memberDescriptionText}</Text>
        <Grid>
          {membersDetails.map((member) => (
            <CustomMemberCard
              key={member.name}
              name={member.name}
              title={member.title}
              office={member.office}
              description={member.description}
              image={member.image}
            />
          ))}
        </Grid>
      </Wrapper>
      <CustomFooter />
    </>
  );
}
