import React from 'react';
import { FaPhoneAlt } from 'react-icons/fa';
import { HiOutlineMailOpen } from 'react-icons/hi';
import { BiSupport } from 'react-icons/bi';
import { AiTwotoneStar } from 'react-icons/ai';
import {
  Wrapper,
  ContactPageWrapper,
  ContactDetailsWrapper,
} from '../styles/Wrapper';
import CustomFooter from '../components/CustomFooter';
import { PageTitle, Heading } from '../styles/Heading';
import { ContactHyperlink } from '../styles/Hyperlink';
import { contactPageIconStyle } from '../styles/Icons';
import {
  pageTitle,
  contactEmail,
  contactPageHeadingPart1,
  contactPageHeadingPart2,
  contactSectionTitle,
  annetteEmail,
  annettePhoneNumber,
  webMasterEmail,
} from '../data/Contact';

export default function Contact() {
  return (
    <>
      <Wrapper>
        <PageTitle>{pageTitle}</PageTitle>
        <Heading>
          {contactPageHeadingPart1}
          <ContactHyperlink href={`mailTo:${contactEmail}`}>
            {contactEmail}
          </ContactHyperlink>
          {contactPageHeadingPart2}
        </Heading>
        <ContactDetailsWrapper>
          <AiTwotoneStar style={contactPageIconStyle} />
          <b> Annette O’Callaghan</b>, NSW Parliamentary Counsel, is the
          Secretary of PCC and is supported by the Secretariat.
        </ContactDetailsWrapper>
        <ContactDetailsWrapper>
          <AiTwotoneStar style={contactPageIconStyle} />
          <b> Jacqueline Edwards</b>, Assistant Parliamentary Counsel, is the
          current Secretariat.
        </ContactDetailsWrapper>
        <Heading>{contactSectionTitle}</Heading>
        <ContactPageWrapper>
          <ContactDetailsWrapper>
            <FaPhoneAlt style={contactPageIconStyle} />
            Telephone:{'  '}
            <ContactHyperlink href={`tel:${annettePhoneNumber}`}>
              <b style={{ marginLeft: '.25rem' }}> 61 2 9321 3333</b>
            </ContactHyperlink>
          </ContactDetailsWrapper>
          <ContactDetailsWrapper>
            <HiOutlineMailOpen style={contactPageIconStyle} />
            Email:
            <ContactHyperlink
              href={`mailTo:${annetteEmail}?subject=Email from PCC website`}
            >
              <b style={{ marginLeft: '.25rem' }}>
                annette.ocallaghan@pco.nsw.gov.au
              </b>
            </ContactHyperlink>
          </ContactDetailsWrapper>
          <ContactDetailsWrapper>
            <BiSupport style={contactPageIconStyle} />
            Webmaster To report difficulties or give feedback, please contact
            the
            <ContactHyperlink
              href={`mailTo:${webMasterEmail}}?subject=Email from PCC website`}
            >
              <b style={{ marginLeft: '0.2rem' }}>Webmaster.</b>
            </ContactHyperlink>
          </ContactDetailsWrapper>
        </ContactPageWrapper>
      </Wrapper>
      <CustomFooter />
    </>
  );
}
