/* Content of the copyright page */

// Page title of the C&D
export const pageTitle = 'Copyright and Disclaimer';

// Heading for copyright div
export const copyrightHeading = 'Copyright';

// Text about the copyright
export const copyrightText =
  "This work is copyright. You may download, display, print and reproduce this material in unaltered form only (retaining this notice) for your personal, non-commercial use or use within your organisation. Apart from any use as permitted under the Copyright Act 1968, all other rights are reserved. Requests for further authorisation should be directed to the Secretary of the Parliamentary Counsel's Committee (";

// Heading for the discalimer
export const disclaimerHeading = 'Disclaimer';

// Text about the discalimer
export const disclaimerText =
  'No warranty is given that the material located on this site, or on any other site referred to on this site, is free from error or omission. Users should exercise their own skill and care with respect to the use of the material. Accordingly, the Commonwealth, New Zealand, States and Territories and their servants and agents expressly disclaim liability for any act done or omission made in reliance on any such material and any consequences of any such act or omission. This notice must not be erased.';
