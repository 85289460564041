/* Member Card component of the PCC website
 * Dependencies
 * Styling - MemberCardStyle.js
 * External - Prop Types
 */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Header,
  Image,
  MemberDetails,
  Title,
  Description,
} from '../styles/MemberCardStyle';

// Main custom card function
// Taking name, title, description, image, office as props value
export default function CustomMemberCard(props) {
  const { name, title, description, image, office } = props;
  return (
    <Card>
      <Header>
        <Image src={image} alt="Members Images" />
      </Header>
      <MemberDetails>
        {name} <Title>{title}</Title>
        <Title>{office}</Title>
        <Description> {description}</Description>
      </MemberDetails>
    </Card>
  );
}

// Defining the prop types
CustomMemberCard.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string,
  office: PropTypes.string,
};

// Assiging the default values to props
CustomMemberCard.defaultProps = {
  name: 'Name',
  description: 'Description',
  image: 'Image',
  office: 'Office',
  title: 'Title',
};
