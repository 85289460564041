/* Content of the Accessibility page */

// Page title of the accessibility
export const pageTitle = 'Accessibility';

// Accessibility page introduction
export const accessibilityIntro =
  "This statement relates to content available on the Australasian Parliamentary Counsel's Committee (PCC) website www.pcc.gov.au. If you have any questions or comments about this statement or the content on this site, please";

// Heading for web design div
export const webDesignHeading = 'Accessible Web Design';

// Text about the webdesign 1st paragraph
export const webDesignTextParagraph1 =
  'This site has been developed to ensure content is available to the widest possible audience, including readers using assistive technology or accessibility features. By adhering to guidelines for accessible web design, we acknowledge the diversity of communication methods, available technologies and abilities of web-users in the community.';

// Text about the webdesign 2nd paragraph
export const webDesignTextParagraph2 =
  " PCC strives to maintain conformance to W3C's Web Content Accessibility Guidelines (WCAG). The public pages on this site meet the criteria for AA compliance with WCAG guidelines, and have been checked using the Wave web accessibility evaluation tool.";

// Heading for the standard compliance
export const standardComplianceHeading = 'Standards Compliance';

// Text about the standard compliance
export const standardComplianceText =
  'This site meets all the coding criteria for compliance with W3C CSS (cascading style sheets) and HTML 4.01 Transitional. For example, check the home page for HTML validity. Visit www.w3c.org for more information.';

// Heading for converting PDF file
export const convertingPDFHeading = 'Converting PDF files';

// Text for converting PDF file
export const convertingPDFTextParagraph1 =
  'The Adobe website provides online conversion tools to convert Adobe PDF files to plain text or HTML files. To access this service go to ';

// Text for converting PDF file
export const convertingPDFTextParagraph2 =
  'Alternatively, the Adobe PDF file can be submitted as a MIME attachment to an email message. For plain text, mail the attached PDF to pdf2txt@adobe.com. For HTML, mail the attached PDF to pdf2html@adobe.com.';
