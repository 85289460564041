import styled from 'styled-components';

// Generic Grid component used across the project
export default styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 0.063rem;
  margin-top: 3.125rem;
  justify-items: center;

  @media screen and (max-width: 1600px) {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0.313rem;
  }

  @media screen and (max-width: 1000px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
    grid-template-columns: 'repeat(1, 1fr)';
  }
`;
