/* Header component of the PCC website
 * Dependencies
 * Styling - Icons.js, Heading.js, Wrapper.js and Hyperlink.js
 * External - react-icons
 * Data - Header.js
 */
import React from 'react';
import { FiLogIn } from 'react-icons/fi';
import { IconContext } from 'react-icons';
import { headerIconStyle } from '../styles/Icons';
import { headerText, portalText, portalLink } from '../data/Header';
import { HeaderWrapper, LogoWrapper } from '../styles/Wrapper';
import { HeaderTitle } from '../styles/Heading';
import { HeaderHyperlink } from '../styles/Hyperlink';
import { LogoImage } from '../styles/Image';
import Logo from '../resources/logo.svg';

// Main function of the Header component
export default function CustomHeader() {
  return (
    <HeaderWrapper>
      <LogoWrapper>
        <LogoImage src={Logo} />
      </LogoWrapper>
      <HeaderTitle>{headerText}</HeaderTitle>
      <HeaderHyperlink href={portalLink} target="_blank">
        <HeaderTitle>{portalText}</HeaderTitle>
        <IconContext.Provider value={headerIconStyle}>
          <FiLogIn />
        </IconContext.Provider>
      </HeaderHyperlink>
    </HeaderWrapper>
  );
}
