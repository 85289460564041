/* Content of the About page of the application */

// Data used in the time line component on about page
export const timeLineData = [
  {
    title: 'Pre-1970’s',
    cardTitle: 'Before PCC established',
    cardDetailedText:
      'Australasian drafting officers communicated informally and collaborated on a number of uniform or complementary legislative schemes, including the Sale of Goods Acts, Partnerships Acts, Business Tenancies legislation, Interpretation Acts and Torrens Title legislation.',
  },
  {
    title: '1970',
    cardTitle: 'PCC established',
    cardDetailedText:
      'PCC was established at a conference in February 1970 attended by drafters from the Commonwealth and most States. The Committee originally included the heads of the drafting offices of the Commonwealth, the States, the Australian Capital Territory and the Northern Territory. The head of the New Zealand Parliamentary Counsel’s Office later joined the Committee. Drafters met again in July of that year to discuss matters including the use of systems for the programming of legislation.',
  },
  {
    title: '1972',
    cardTitle: 'Process for instructing PCC formalised',
    cardDetailedText:
      'The Attorneys General, meeting in Sydney in March 1972, decided that all proposals for the drafting of uniform legislation should be referred to PCC. An instructor would then be responsible for sending instructions to the drafter. Examples of projects drafted by PCC and relevant dates are included below. The complete list of national uniform legislation, including references to the legislation of participating jurisdictions and a description of the nature of the uniform scheme, is available here [link to page]. ',
  },
  {
    title: '1985',
    cardTitle: 'Australia Acts (Request) Acts',
    cardDetailedText:
      'All States enacted Acts requesting and consenting to the enactment of UK and Commonwealth legislation to terminate the UK Parliament’s power to legislate for Australia and, among other matters, to confer certain powers on State Parliaments.',
  },
  {
    title: '1996',
    cardTitle: 'National Electricity (South Australia) Act 1996',
    cardDetailedText:
      'Australasian drafting officers communicated informally and collaborated on a number of uniform or complementary legislative schemes, including the Sale of Goods Acts, Partnerships Acts, Business Tenancies legislation, Interpretation Acts and Torrens Title legislation.',
  },
  {
    title: '2000',
    cardTitle: 'Crimes at Sea Act 2000 (Cth)',
    cardDetailedText: '',
  },
  {
    title: '2001',
    cardTitle: 'Corporations Act 2001 (Cth)',
    cardDetailedText: '(following an earlier Corporations Act in 1989)',
  },
  {
    title: '2005',
    cardTitle: 'Model Defamation Provisions first approved',
    cardDetailedText: '',
  },
  {
    title: '2007',
    cardTitle: 'Model Uniform Evidence Bill ',
    cardDetailedText:
      '(the Bill was based on the Evidence Act (NSW) 1995 and was later amended in 2010 and 2019)',
  },
  {
    title: '2008',
    cardTitle: 'National Gas (South Australia) Act 2008 (applied law)',
    cardDetailedText:
      'The Attorneys General, meeting in Sydney in March 1972, decided that all proposals for the drafting of uniform legislation should be referred to PCC. An instructor would then be responsible for sending instructions to the drafter. Examples of projects drafted by PCC and relevant dates are included below. The complete list of national uniform legislation, including references to the legislation of participating jurisdictions and a description of the nature of the uniform scheme, is available here [link to page]. ',
  },
  {
    title: '2009',
    cardTitle: 'Health Practitioner Regulation National Law',
    cardDetailedText: '',
  },
];

// Page title of the about page
export const pageTitle = 'About PCC';

// Heading used for what we do div
export const doHeading = 'What we do';

// Heading used for history div
export const historyHeading = 'History of PCC';

// Text describing what we do at pcc
export const whatWeDoText =
  'PCC meets several times a year to discuss proposed changes to national scheme legislation and share insights and advice about the management of drafting offices. The Committee applies a collaborative approach to the drafting of national scheme legislation. While one legislative drafting office will lead the project, all offices are invited to comment to ensure jurisdiction-specific issues are addressed. The Committee supports the professional development of drafters and publishing, editorial and corporate services staff by organising cross-jurisdictional conferences and forums.';
