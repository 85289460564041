/* Various heading component used across the projects */
import styled from 'styled-components';

// Generic heading for project
export const Heading = styled.h1`
  font-family: ${(props) => props.theme.font};
  line-height: 1.8;
  font-size: 1.1rem;
  font-weight: bold;
  align-content: center;
  margin-bottom: 0.5rem;
  margin-top: 1.5rem;

  @media screen and (max-width: 1110px) {
    font-size: 1rem;
  }
`;

// Generic heading for header
export const HeaderTitle = styled.h1`
  color: ${(props) => props.theme.secondaryColor};
  text-align: left;
  width: 79%;
  font-size: 2rem;
  margin-left: 1rem;

  @media screen and (max-width: 1400px) {
    width: 70%;
    font-size: 1.8rem;
    line-height: 1.6;
  }

  @media screen and (max-width: 530px) {
    width: 48%;
    line-height: 1.4;
    font-size: 1.4rem;
  }
`;

// Generic page title
export const PageTitle = styled.h1`
  font-size: 1.4rem;
  font-weight: bold;
  margin-bottom: 1rem;
  font-family: ${(props) => props.theme.font};
  line-height: 1.6;
  margin-top: 1rem;

  @media screen and (max-width: 1110px) {
    font-size: 1.2rem;
  }
`;
