/* Various text styling used across the project */
import styled from 'styled-components';

// Generic text styling used across project
export const Text = styled.p`
  margin-bottom: 1rem;
  font-family: ${(props) => props.theme.font};
  font-size: 1rem;
  text-alig: left;
  width: 95%;

  @media screen and (max-width: 700px) {
    font-size: 0.9rem;
    text-alig: left;
    width: 100%;
  }
`;

// Text styling used for footer acknowledge text
export const FooterText = styled.p`
  width: 100%;
  margin-right: 0.313rem;

  @media screen and (max-width: 700px) {
    font-size: 0.625rem;
  }
`;

// Text style for text beneath footer icons
export const FooterIconText = styled.p`
  text-align: center;

  @media screen and (max-width: 700px) {
    font-size: 0.75rem;
    margin-right: 0.313rem;
    margin-left: 0.313rem;
  }

  @media screen and (max-width: 530px) {
    font-size: 0.625rem;
  }
`;

// Text styling used at home page
export const HomeText = styled.p`
  font-family: ${(props) => props.theme.font};
  font-size: 1rem;
  text-alig: left;
  width: 95%;
  margin-right: 0.1rem;
  margin-top: 0.8rem;

  @media screen and (max-width: 700px) {
    font-size: 0.9rem;
    text-alig: left;
    width: 100%;
  }
`;
