import styled from 'styled-components';

// Component for carosuel image on the home page
export const HomePageImage = styled.img`
  margin-top: 1rem;
  height: 45%;
  width: 45%;
  margin-right: 2rem;

  @media screen and (max-width: 530px) {
    margin-top: 1rem;
    height: 55%;
    width: 100%;
  }
`;

// PCC logo holder for component in the side nav panel
export const LogoImage = styled.img`
  width: 100%;
  height: 5rem;

  @media screen and (max-width: 700px) {
  }
`;

// generic svg component for across the project
export const SVG = styled.svg`
  fill: ${(props) => (props.color ? props.color : props.theme.primaryColor)};
  width: 1.125rem;
  height: 1.125rem;
  cursor: ${(props) => (props.loading ? 'not-allowed' : 'pointer')};
`;
