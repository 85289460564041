/* Various Hyperlink used across projects */
import styled from 'styled-components';

// anchor used generically
export const Hyperlink = styled.a`
  font-size: 1.125rem;
  font-weight: bold;
  font-family: ${(props) => props.theme.font};
  line-height: 1.8;
  margin-bottom: 1.5rem;
`;

// anchor used for header component
export const HeaderHyperlink = styled.a`
  display: flex;
  align-items: center;
`;

// anchor used for contact us page
export const ContactHyperlink = styled.a`
  text-decoration: underline;
  color: blue;
`;

export const LegislationHyperlink = styled.a`
  font-size: 0.9rem;
  color: #0b186d;
  font-weight: bold;
  font-family: ${(props) => props.theme.font};

  @media screen and (max-width: 1110px) {
    font-size: 0.5rem;
  }
`;
