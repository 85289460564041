/* National Uniform legislation page of the application featuring various NUL */
/* Dependencies
 * Components - CustomFooter.jsx, CustomLegislationTab.jsx
 * Styling  Wrapper.js, Heading.js
 * Data - Legislation.js
 */

import React from 'react';
import CustomLegTab from '../components/CustomLegislationTab';
import CustomFooter from '../components/CustomFooter';
import { Text } from '../styles/Text';
import { Heading, PageTitle } from '../styles/Heading';
import { Wrapper } from '../styles/Wrapper';
import {
  categoryData,
  heading,
  pageTitle,
  tabPageTitle,
} from '../data/Legislation';

// Main function of the NUL calling its components
export default function UniformLegislation() {
  return (
    <>
      <Wrapper>
        <PageTitle>{pageTitle}</PageTitle>
        <Heading>{heading}</Heading>
        {categoryData.map((data) => (
          <div key={data.heading}>
            <Text style={{ fontWeight: 'bold' }}>{data.heading}</Text>
            <Text>{data.description}</Text>
          </div>
        ))}
        <PageTitle>{tabPageTitle}</PageTitle>
        <CustomLegTab />
      </Wrapper>
      <CustomFooter />
    </>
  );
}
