/* Timeline component of the PCC website on about page
 * Dependencies
 * Styling - Wrapper.js
 * External - react-chrono
 */
import React from 'react';
import { Chrono } from 'react-chrono';
import { timeLineData } from '../data/About';
import { TimelineWrapper, TimelineSubWrapper } from '../styles/Wrapper';

// Main function for the Custom Timeline
export default function CustomTimeline() {
  return (
    <TimelineWrapper>
      <TimelineSubWrapper>
        <Chrono
          items={timeLineData}
          hideControls
          scrollable
          mode="HORIZONTAL"
          theme={{
            primary: '#0b186d',
            secondary: '#fff',
            cardBgColor: '#0b186d',
            cardForeColor: '#fff',
          }}
        />
      </TimelineSubWrapper>
    </TimelineWrapper>
  );
}
