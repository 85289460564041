import styled from 'styled-components';

// Component defining layout of the website
// change the percentage of left margin to change
// the width of left navigation pane of website
export default styled.div`
  margin-left: 11.5%;

  @media screen and (max-width: 1400px) {
    margin-left: 17%;
  }

  @media screen and (max-width: 1110px) {
    margin-left: 10%;
  }

  @media screen and (max-width: 700px) {
    margin-left: 0%;
  }
`;
