/* Content of the footer component */

// text and page link of the footer elements
export const footerContent = [
  {
    id: 1,
    text: 'Copyright & Disclaimer',
    link: '/copyright',
  },
  {
    id: 2,
    text: 'Accessibility',
    link: '/accessibility',
  },
  {
    id: 3,
    text: 'Sitemap',
    link: '/sitemap',
  },
  {
    id: 4,
    text: 'Privacy',
    link: '/privacy',
  },
];

// acknowledgement text at bottom
export const footerText =
  'We acknowledge the traditional owners of this land and pay respect to Elders past, present and emerging.';
