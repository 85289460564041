import React, { useContext } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import Analytics from 'react-router-ga';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import Layout from '../styles/Layout';
import Navigation from '../components/CustomSideBar';
import Home from '../pages/Home';
import About from '../pages/About';
import Contact from '../pages/Contact';
import Instruction from '../pages/Instruction';
import Member from '../pages/Member';
import Legislations from '../pages/Legislation';
import Copyright from '../pages/Copyright';
import Accessibility from '../pages/Accessibility';
import Sitemap from '../pages/Sitemap';
import Privacy from '../pages/Privacy';
import RelatedLinks from '../pages/RelatedLinks';
import Publications from '../pages/Publication';
import CustomHeader from '../components/CustomHeader';
import GlobalStyle from '../styles/GlobalStyle';
import { ThemeContext } from '../context/ThemeContext';

export default function AppRouter() {
  const { theme } = useContext(ThemeContext);
  document.title = "Parliamentary Counsel's Committee";
  return (
    <StyledThemeProvider theme={theme}>
      <Router>
        <CustomHeader />
        <Navigation />
        <GlobalStyle />
        <Layout>
          <Analytics id="UA-56816798-2" debug>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/about" component={About} />
              <Route exact path="/contact" component={Contact} />
              <Route exact path="/members" component={Member} />
              <Route exact path="/legislations" component={Legislations} />
              <Route exact path="/procedures" component={Instruction} />
              <Route exact path="/copyright" component={Copyright} />
              <Route exact path="/accessibility" component={Accessibility} />
              <Route exact path="/sitemap" component={Sitemap} />
              <Route exact path="/privacy" component={Privacy} />
              <Route exact path="/relatedLinks" component={RelatedLinks} />
              <Route exact path="/publications" component={Publications} />
              <Redirect from="*" to="/" />
            </Switch>
          </Analytics>
        </Layout>
      </Router>
    </StyledThemeProvider>
  );
}
