/* Content of the National Uniform Legislation page */

// Alphabets appearing at the top of tabs in NUL page
export const tabHeader = [
  'A B C',
  'D E F',
  'G H I',
  'J K L',
  'M N O',
  'P Q R',
  'S T U V',
  'W X Y Z',
];

// Content displayed in the National Uniform Legislation page
// Links, texts and sub sections
export const legislationData = [
  {
    title: 'Australian Road Rules',
    current: [
      {
        hyperLinkText: 'Australian Road Rules',
        link: 'NationalUniformLaw/2021/Australian-Road-Rules-28May2021.pdf',
        blurb: '',
      },
    ],
    historical: [
      {
        link: '',
        hyperLinkText: '',
        blurb: '',
      },
    ],
    amendment: [
      {
        link: 'NationalUniformLaw/2021/pcc-567-d14.pdf',
        hyperLinkText:
          'Australian Road Rules (Personal Mobility Devices) Amendment 2021',
        blurb: '',
      },
    ],
  },
  {
    title: 'Child Protection (International Measures) Bill',
    current: [
      {
        hyperLinkText: 'Model Child Protection (International Measures) Bill',
        link: 'NationalUniformLaw/2021/V03H_B99_0312_DR-web.pdf',
        blurb:
          ' approved by the Standing Committee of Attorneys-General on 8 November 2002.',
      },
    ],
    historical: [
      {
        link: '',
        hyperLinkText: '',
        blurb: '',
      },
    ],
    amendment: [
      {
        link: '',
        hyperLinkText: '',
        blurb: '',
      },
    ],
  },
  {
    title: 'Commercial Arbitration',
    current: [
      {
        hyperLinkText: 'Model Commercial Arbitration Bill 2010',
        link: 'NationalUniformLaw/2021/Commercial-Arbitration-7May2010.pdf',
        blurb:
          ' approved by the Standing Committee of Attorneys-General on 7 May 2010.',
      },
    ],
    historical: [
      {
        link: '',
        hyperLinkText: '',
        blurb: '',
      },
    ],
    amendment: [
      {
        link: '',
        hyperLinkText: '',
        blurb: '',
      },
    ],
  },
  {
    title: 'Criminal Code',
    current: [
      {
        hyperLinkText: 'Model Criminal Code',
        link: 'NationalUniformLaw/2021/crime%20(composite-2007)-website.pdf',
        blurb:
          ' has been prepared as a law of a State or Territory. The Code is a collation of draft provisions in various separate reports of the Model Criminal Code Officers Committee (and its successor, the Model Criminal Law Officers Committee) of the Standing Committee of Attorneys-General.',
      },
    ],
    historical: [
      {
        link: '',
        hyperLinkText: '',
        blurb: '',
      },
    ],
    amendment: [
      {
        link: '',
        hyperLinkText: '',
        blurb: '',
      },
    ],
  },
  {
    title: 'Defamation Provisions',
    current: [
      {
        link: 'NationalUniformLaw/2021/Consolidated_Model_Defamation_Provisions.pdf',
        hyperLinkText: 'Consolidated Model Defamation Provisions',
        blurb:
          ' (as at 27 July 2020) including Amendments in Model Defamation Amendment Provisions 2020.',
      },
    ],
    historical: [
      {
        title: '',
        link: 'NationalUniformLaw/2021/Original_Model_Defamation_Provisions_2005.pdf',
        hyperLinkText: 'Original Model Defamation Provisions',
        blurb:
          ' (including explanatory note) approved by the Standing Committee of Attorneys-General on 21 March 2005.',
      },
    ],
    amendment: [
      {
        link: 'NationalUniformLaw/2021/Model_Defamation_Amendment_Provisions_2020.pdf',
        hyperLinkText: 'Model Defamation Amendment Provisions 2020',
        blurb:
          ' (including explanatory note) approved by the Council of Attorneys-General on 27 July 2020.',
      },
    ],
  },
  {
    title: 'Domestic Violence Orders',
    current: [
      {
        link: 'NationalUniformLaw/2021/Domestic-Violence-Orders-Model-Provisions-11December2015.pdf',
        hyperLinkText:
          'Domestic Violence Orders (National Recognition) Model Provisions',
        blurb: '',
      },
    ],
    historical: [
      {
        link: '',
        hyperLinkText: '',
        blurb: '',
      },
    ],
    amendment: [
      {
        link: '',
        hyperLinkText: '',
        blurb: '',
      },
    ],
  },
  {
    title: 'Health Practitioner Regulation National Law',
    current: [
      {
        link: 'NationalUniformLaw/2021/b2009-079SCH.pdf',
        hyperLinkText: 'Health Practitioner Regulation National Law',
        blurb:
          ' approved by the Australian Health Workforce Ministerial Council in September 2009.',
      },
    ],
    historical: [
      {
        link: '',
        hyperLinkText: '',
        blurb: '',
      },
    ],
    amendment: [
      {
        link: '',
        hyperLinkText: '',
        blurb: '',
      },
    ],
  },
  {
    title: 'Heavy Vehicle Charges Model Law',
    current: [
      {
        link: 'NationalUniformLaw/2021/Heavy-Vehicle-Charges-Model-Law-13May2021.pdf',
        hyperLinkText: 'Heavy Vehicle Charges Model Law',
        blurb:
          'as amended by the Heavy Vehicle Charges Amendment (Annual Registration Charges) Model Law 2021.',
      },
    ],
    historical: [
      {
        link: '',
        hyperLinkText: '',
        blurb: '',
      },
    ],
    amendment: [
      {
        link: 'NationalUniformLaw/2021/pcc-572-d03.pdf',
        hyperLinkText:
          'Heavy Vehicle Charges Amendment (Annual Registration Charges) Model Law 2021.',
        blurb: '',
      },
    ],
  },
  {
    title: 'Legal Profession legislation',
    current: [
      {
        link: '',
        hyperLinkText: '2nd Edition',
        blurb:
          'as amended by the Heavy Vehicle Charges Amendment (Annual Registration Charges) Model Law 2021.',
      },
      {
        link: 'NationalUniformLaw/2021/pcc-legal-prof-2006-d21.pdf',
        hyperLinkText: 'Model provisions',
        blurb:
          " approved by the Standing Committee of Attorneys-General in August 2006. This revised and re-arranged Edition includes comparative references ('cf') in the headnotes containing information in relation to the Victorian and NSW legal profession legislation, and the equivalent provisions in the 1st Edition of the Model provisions.",
      },
      {
        link: 'NationalUniformLaw/2021/b02-268j-d13.pdf',
        hyperLinkText: 'Model Regulations',
        blurb: ' were approved by the Standing Committee on 11 April 2007.',
      },
    ],
    historical: [
      {
        link: '',
        hyperLinkText: '1st Edition',
        blurb:
          'as amended by the Heavy Vehicle Charges Amendment (Annual Registration Charges) Model Law 2021.',
      },
      {
        link: 'NationalUniformLaw/2021/b02-268g-d08A.pdf',
        hyperLinkText: 'First version of the Model provisions',
        blurb:
          ' as approved by the Standing Committee of Attorneys-General in July 2004.',
      },
      {
        link: 'NationalUniformLaw/2021/b02-268h-d19.pdf',
        hyperLinkText: 'Model Regulations',
        blurb:
          ' were approved by the Standing Committee on 28/29 July 2005 for adoption in connection with the Model provisions.',
      },
    ],
    amendment: [],
  },
  {
    title: 'Light Vehicle Standards',
    current: [
      {
        link: 'NationalUniformLaw/2021/Australian-Light-Vehicle-Standards-Rules-2015-20-November-2020.pdf',
        hyperLinkText: 'Australian Light Vehicle Standards Rules 2015,',
        blurb:
          ' as amended by Amendments approved on 22 November 2019 and 20 November 2020.',
      },
    ],
    historical: [],
    amendment: [
      {
        link: 'NationalUniformLaw/2021/pcc-534_full.pdf',
        hyperLinkText: '22 November 2019 ',
        blurb: '',
      },
      {
        link: 'NationalUniformLaw/2021/pcc-555-d09.pdf',
        hyperLinkText: '20 November 2020',
        blurb: '',
      },
    ],
  },
  {
    title: 'Personal Property Securities (Commonwealth Powers) Bill',
    current: [
      {
        link: 'NationalUniformLaw/2021/pcc-355-d14.pdf',
        hyperLinkText:
          'Model referral Bill for the Personal Property Securities (Commonwealth Powers) Bill 2009 ',
        blurb:
          ' approved by the Standing Committee of Attorneys-General in June 2009.',
      },
      {
        link: 'NationalUniformLaw/2021//b2009-074_Tabled_Text.pdf',
        hyperLinkText: 'The tabled text',
        blurb:
          ' referred to in that Bill and as first tabled in the NSW Legislative Assembly is also available.',
      },
    ],
    historical: [],
    amendment: [],
  },
  {
    title: 'Proportionate Liability Provisions',
    current: [
      {
        link: 'NationalUniformLaw/2021/proportionate%20liability%20model%20provisions.pdf',
        hyperLinkText: 'Proportionate Liability Model Provisions',
        blurb:
          ' released by the Standing Committee on Law and Justice in October 2013.',
      },
    ],
    historical: [],
    amendment: [],
  },
  {
    title: 'Spent Convictions Bill',
    current: [
      {
        link: 'NationalUniformLaw/2021/Spent_Convictions_Bill_2009.pdf',
        hyperLinkText: 'Model Spent Convictions Bill 2009',
        blurb:
          ' released by the Standing Committee of Attorneys-General in November 2009.',
      },
    ],
    historical: [],
    amendment: [],
  },
  {
    title: 'Succession (International Wills)',
    current: [
      {
        link: 'NationalUniformLaw/2021/pcc-394-d08.pdf',
        hyperLinkText:
          'Model Succession Amendment (International Wills) Bill 2012 ',
        blurb:
          ' to give effect to a decision of the Standing Committee of Attorneys-General on 2 July 2010. The model provisions have been drafted as amendments to the Succession Act 2006 of New South Wales, which reflects the model succession law project on wills enacted in other jurisdictions. Each jurisdiction will need to tailor the Amendments to take account of their local legislation.',
      },
    ],
    historical: [],
    amendment: [],
  },
  {
    title: 'Transport of Dangerous Goods by Road or Rail',
    current: [
      {
        link: 'NationalUniformLaw/2021/Model%20Act%20on%20the%20Transport%20of%20Dangerous%20Goods%20by%20Road%20or%20Rail%20-%202016-11-04.pdf',
        hyperLinkText:
          'Model Act on the Transport of Dangerous Goods by Road or Rail',
        blurb: '',
      },
      {
        link: 'NationalUniformLaw/2021/Model-Subordinate-Instrument-on-Transport-of-Dangerous-Goods-2020-06-05.pdf',
        hyperLinkText:
          'Model Subordinate Instrument on the Transport of Dangerous Goods by Road or Rail',
        blurb:
          ' (as at 5 June 2020) including Amendments in  Model Subordinate Instrument on the Transport of Dangerous Goods by Road or Rail Amendment 2020.',
      },
    ],
    historical: [],
    amendment: [
      {
        link: '',
        hyperLinkText: '',
        blurb: '',
      },
      {
        link: 'NationalUniformLaw/2021/Model-Subordinate-Instrument-on-Transport-of-Dangerous-Goods-Amendment-2020.pdf',
        hyperLinkText:
          'Model Subordinate Instrument on the Transport of Dangerous Goods by Road or Rail',
      },
    ],
  },
  {
    title: 'Uniform Evidence Bill',
    current: [
      {
        link: 'NationalUniformLaw/2021//Evidence_Model_Bill_Nov-2019.pdf',
        hyperLinkText: 'Model Uniform Evidence Bill',
        blurb:
          " prepared by the Parliamentary Counsel's Committee and endorsed by the Standing Committee of Attorneys-General on 26 July 2007, as amended by model provisions that the Committee agreed to include in the Model Uniform Evidence Bill on 7 May 2010 and amendments agreed to by the Council of Attorneys-General on 29 November 2019. ",
      },
    ],
    historical: [],
    amendment: [
      {
        link: 'NationalUniformLaw/2021/pcc-318-19-d12-web.pdf',
        hyperLinkText: 'Evidence Amendment Bill 2007, ',
      },
      {
        link: 'NationalUniformLaw/2021/7-May-2010-second-tranche.pdf',
        hyperLinkText: '7 May 2010 amendments, ',
      },
      {
        link: 'NationalUniformLaw/2021/29 November 2019 amendments.pdf',
        hyperLinkText: '29 November 2019 amendments, ',
      },
    ],
  },
  {
    title: 'Work Health and Safety',
    current: [
      {
        link: 'NationalUniformLaw/2021/Model-WHS-Bill-9%20December%202019.pdf',
        hyperLinkText: 'Model Work Health and Safety Bill',
        blurb: '',
      },
      {
        link: 'NationalUniformLaw/2021/pcc-394-d08.pdf',
        hyperLinkText: 'Model Work Health and Safety Regulations,',
        blurb:
          ' as amended by the Model Work Health and Safety Regulations (Hazardous Chemicals) Amendment 2020.',
      },
    ],
    historical: [],
    amendment: [
      {
        link: 'NationalUniformLaw/2021/Model-WHS-Regulations-Amendments-1January2021.pdf',
        hyperLinkText:
          'Model Work Health and Safety Regulations (Hazardous Chemicals) Amendment 2020.',
      },
      {
        link: 'NationalUniformLaw/2021/Model-WHS-Act-Amendments-%209%20December%202019.pdf',
        hyperLinkText: '9 December 2019 amendments',
      },
    ],
  },
];

export const categoryData = [
  {
    id: 1,
    heading: '1. National applied laws',
    description:
      'Legislation enacted by one jurisdiction is applied by participating jurisdiction, as in force from time to time, as a law of the jurisdiction.',
  },
  {
    id: 2,
    heading: '2. National model legislation',
    description:
      'Model provisions are enacted by participating jurisdictions, including variations necessary to give effect to the uniform policy in the jurisdiction.',
  },
  {
    id: 3,
    heading:
      '3. State legislation referring State legislative power to the Commonwealth',
    description: '',
  },
  {
    id: 4,
    heading:
      ' 4. Legislation of a jurisdiction identified as legislation that will or may be followed by other jurisdictions',
    description:
      'The jurisdiction may seek comments from other PCC members to ensure the legislation may be drafted in a way that promotes the consistency of uniform legislation.',
  },
];

export const pageTitle = ' National Uniform Legislation Official versions';

export const heading =
  'National uniform legislation drafted by PCC may be categorised as follows -';

export const tabPageTitle = 'Uniform Legislation and Amending Acts';
