/* Footer component of the PCC website
 * Dependencies
 * Styling - Icons.js, Wrapper.js, Text.js
 * External - react-icons
 * Data - Footer.js
 */

import React from 'react';
import { FaSitemap, FaCopyright, FaAccessibleIcon } from 'react-icons/fa';
import { MdPrivacyTip } from 'react-icons/md';
import { NavLink } from 'react-router-dom';
import { FooterIconBox } from '../styles/Icons';
import { FooterWrapper } from '../styles/Wrapper';
import { footerContent, footerText } from '../data/Footer';
import { FooterText, FooterIconText } from '../styles/Text';

// Function rendering the icons in the footer blocks
const renderIcon = (id) => {
  switch (id) {
    case 1:
      return <FaCopyright size={25} />;
    case 2:
      return <FaAccessibleIcon size={25} />;
    case 3:
      return <FaSitemap size={25} />;
    case 4:
      return <MdPrivacyTip size={25} />;
    default:
      return 'Image Icon';
  }
};

// Main Footer function mapping its components
export default function CustomFooter() {
  return (
    <FooterWrapper>
      <FooterText>{footerText}</FooterText>
      {footerContent.map((footerData) => (
        <FooterIconBox key={footerData.id}>
          <NavLink key={footerData.id} exact to={footerData.link}>
            {renderIcon(footerData.id)}
            <FooterIconText>{footerData.text}</FooterIconText>
          </NavLink>
        </FooterIconBox>
      ))}
    </FooterWrapper>
  );
}
