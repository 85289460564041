/* Content of the site map page */

// Various entries at sitemap page
export const siteMapData = [
  {
    link: '/',
    text: 'Home',
  },
  {
    link: '/about',
    text: 'About PCC',
  },
  {
    link: '/members',
    text: 'Members',
  },
  {
    link: '/legislations',
    text: 'National Uniform Legislation',
  },
  {
    link: '/publications',
    text: 'Publication',
  },
  {
    link: '/procedures',
    text: 'Procedure',
  },
  {
    link: '/relatedLinks',
    text: 'Related Links',
  },
  {
    link: '/contact',
    text: 'Contact',
  },
  {
    link: '/copyright',
    text: 'Copyright',
  },
  {
    link: '/sitemap',
    text: 'Sitemap',
  },
  {
    link: '/privacy',
    text: 'Privacy',
  },
];

// Page title of the sitemap page
export const pageTitle = 'Sitemap';
